import useAxios from "../../../axios/useAxios";
import Error from "../../Reusable/Error";
import Loader from "../../Reusable/Loader";
import CardContainer from "../../UI/Layout/CardContainer";
import { Fragment, useEffect } from "react";
import ProjectCard from "ui/cards/project-card";
import ContainerTitle from "ui/wrappers/container-title";
import ContainerWrapper from "ui/wrappers/container-wrapper";

function ProjectHome() {
  const { response: projects, loading, error, axiosFetch } = useAxios();

  const params = {
    limit: 4,
    noFilter: true
  };

  async function getProjects() {
    await axiosFetch({
      url: "/projects",
      params
    });
  }

  useEffect(
    () => {
      getProjects();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  const allProjects =
    [...(projects?.projects ? projects.projects : []), projects.currentProject] ?? [];

  return (
    <ContainerWrapper>
      <ContainerTitle title={"projects"} navTo={"/projects"} />
      <CardContainer>
        {allProjects.map((post, index) => {
          if (!post) return null;

          return (
            <Fragment key={post._id}>
              <ProjectCard post={post} currentIndex={index} />
            </Fragment>
          );
        })}
      </CardContainer>
    </ContainerWrapper>
  );
}

export default ProjectHome;
