export const infoDataText = {
  en: {
    stats: [
      { title: "Years", number: "31" },
      { title: "Projects", number: "17+" },
      { title: "Regions", number: "10" },
      { title: "Beneficiaries", number: "150K+" },
      { title: "Partners", number: "40+" }
    ]
  },
  de: {
    stats: [
      { title: "Jahre", number: "31" },
      { title: "Projekte", number: "17+" },
      { title: "Regionen", number: "10" },
      { title: "Begünstigte", number: "150K+" },
      { title: "Partner", number: "40+" }
    ]
  },
  am: {
    stats: [
      { title: "Տարի", number: "31" },
      { title: "Նախագիծ", number: "17+" },
      { title: "Մարզ", number: "10" },
      { title: "Շահառու", number: "150,000" },
      { title: "Գործընկեր", number: "40+" }
    ]
  }
};
