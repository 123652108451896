import VolunteersModal from "./VolunteersModal";
import { cardContent } from "./card-content";
import CardContainer from "components/UI/Layout/CardContainer";
import { useAtomValue } from "jotai";
import { useState } from "react";
import VolunteerCard from "ui/cards/volunteer-card";
import ContainerTitle from "ui/wrappers/container-title";
import ContainerWrapper from "ui/wrappers/container-wrapper";
import { localeAtom } from "utils/locale/state";

function Volunteer() {
  const [modalShow, setModalShow] = useState(false);
  const content = cardContent;
  const locale = useAtomValue(localeAtom);

  return (
    <ContainerWrapper>
      <ContainerTitle title={"volunteers"} />
      <CardContainer>
        {content.map((cardData, index) => (
          <VolunteerCard data={cardData} key={index} />
        ))}
      </CardContainer>
      <VolunteersModal show={modalShow} onHide={() => setModalShow(false)} language={locale} />
    </ContainerWrapper>
  );
}

export default Volunteer;
