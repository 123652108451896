import { atom } from "jotai";

// cleans the string returned from localStorage getter
function cleanStorageString(defaultValue = "am") {
  const value = localStorage.getItem("lang");
  return value ? value.replace(/"/g, "") : defaultValue;
}

const languageGetter = atom(cleanStorageString("am"));

export const localeAtom = atom(
  (get) => get(languageGetter),
  (get, set, newStr) => {
    set(languageGetter, newStr);
    localStorage.setItem("lang", newStr);
  }
);
