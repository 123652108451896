import { headerContent } from "./content";
import { useAtomValue } from "jotai";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const ContainerTitle = ({ title, navTo }) => {
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";
  const translation = headerContent[language];

  return (
    <div
      className={classNames(
        "mt-4 flex items-center pb-1 pt-3 uppercase text-prmDark",
        navTo ? "justify-between" : "justify-start"
      )}
    >
      <h3
        className={classNames(
          "text-prmOrange sm:text-3xl",
          isArm ? "font-art text-2xl" : "text-xl font-bold"
        )}
      >
        {translation[title]}
      </h3>
      {navTo && (
        <a
          href={navTo}
          className="cursor-pointer rounded-full bg-prmOrange px-3 py-2 text-xs uppercase text-white no-underline hover:bg-prmOrangeHover sm:text-sm lg:px-4"
        >
          {translation.seeAll}
        </a>
      )}
    </div>
  );
};

export default ContainerTitle;
