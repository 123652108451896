import { FormLabel, InputWrapper } from "../../UI/InputElements";
import { donationTranslatedText } from "../content";
import { currencyAtom, defaultValuesAtom, errorAtom } from "../state";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getDisplayValue } from "utils";
import { localeAtom } from "utils/locale/state";

const currencies = [
  {
    text: "amd",
    icon: "֏",
    code: "051"
  },
  {
    text: "usd",
    icon: "$",
    code: "840"
  },
  {
    text: "eur",
    icon: "€",
    code: "978"
  },
  {
    text: "rub",
    icon: "₱",
    code: "648"
  }
];

function CustomValues() {
  const [currency, setCurrency] = useAtom(currencyAtom);
  const [selected, setSelected] = useAtom(defaultValuesAtom);

  const [errorMessage, setErrorMessage] = useAtom(errorAtom);

  const language = useAtomValue(localeAtom);
  const { form: { amountLabel, validationAmountLabel } = {} } = donationTranslatedText[language];

  const [value, setValue] = useState(0);
  const isError = errorMessage && !value && !selected;

  function validatedLabel() {
    if (isError) {
      return validationAmountLabel;
    }

    return `${amountLabel} *`;
  }

  function setDonationValue(e) {
    const cleanInputValue = e.target.value.replace(/\D/g, "");

    setErrorMessage(false);

    if (parseInt(cleanInputValue) > 250000) return;

    setSelected(cleanInputValue);
    setValue(cleanInputValue);
  }

  function handleCurrencyChange(e, curr) {
    e.preventDefault();
    setErrorMessage(false);
    setCurrency(curr);
    setSelected(0);
  }

  return (
    <FormLabel isError={isError} forInput={"amount"} label={validatedLabel()}>
      <InputWrapper>
        <div className="flex  w-full justify-between">
          <span className="self-center text-xl font-semibold text-zinc-600">{currency.icon}</span>
          <input
            id="amount"
            className="no-spinner ml-1 h-12 w-full  p-1 font-num text-3xl font-bold text-prmBlue "
            type="text"
            onChange={setDonationValue}
            value={getDisplayValue(selected || value)}
            max={250000}
            required
          />

          <DropdownButton align="end" id="dropdown-currency" title={currency.text.toUpperCase()}>
            {currencies
              .filter((cur) => cur.text !== currency.text)
              .map((cur, i) => (
                <Dropdown.Item
                  as="button"
                  key={`currency-${i}`}
                  onClick={(e) => handleCurrencyChange(e, cur)}
                >
                  {cur.text.toUpperCase()}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </div>
      </InputWrapper>
    </FormLabel>
  );
}

export default CustomValues;
