import { classNames } from "../../../utils";

const InfoGraphicItem = (props) => {
  const { title, number } = props;
  return (
    <div className={classNames("flex flex-col items-center justify-center p-2")}>
      <div className="text-2xl font-bold text-prmOrange sm:text-4xl lg:text-5xl">{number}</div>
      <div className="text-sm font-light uppercase tracking-wide text-prmDark md:text-base">
        {title}
      </div>
    </div>
  );
};

export default InfoGraphicItem;
