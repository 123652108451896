import DonationForm from "./DonationForm";
import { defaultValuesAtom, donateModalAtom, donorDetailsAtom, errorAtom } from "./state";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useSetAtom } from "jotai";
import { RESET, useResetAtom } from "jotai/utils";
import { Modal } from "react-bootstrap";

function DonateModal(props) {
  const setIsModalOpen = useSetAtom(donateModalAtom);
  const setAmount = useSetAtom(defaultValuesAtom);
  const setErrorMessage = useSetAtom(errorAtom);
  const resetDetails = useResetAtom(donorDetailsAtom);

  function closeModal() {
    setIsModalOpen(false);
    setErrorMessage(false);
    resetDetails(RESET);
    setAmount(0);
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="w-full text-prmDark">
          <DonationForm />
        </div>
      </Modal.Body>
      <button
        onClick={closeModal}
        className="closeButton right-8 top-8 rounded-full font-bold lg:right-6 lg:top-6"
      >
        <XCircleIcon className="h-6 w-6 text-white" />
      </button>
    </Modal>
  );
}

export default DonateModal;
