import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { useAtomValue } from "jotai";
import { classNames, transformedImageUrl } from "utils";
import { localeAtom } from "utils/locale/state";

const VolunteerCard = ({ data }) => {
  const locale = useAtomValue(localeAtom);
  const { title, paragraph, phoneActionButton, mailActionButton } = data[locale];
  const isArm = locale === "am";

  const cardImageUrl = transformedImageUrl({ url: data.cover, ratio: "9:11", width: 600 });

  function onEmailSend() {
    window.location.href = `mailto:info@hoh.am?subject=${title}`;
  }
  function onPhoneCall() {
    window.open("tel:+37410242968");
  }

  return (
    <div
      className={classNames(
        "relative aspect-square overflow-hidden lg:aspect-[9/11]",
        "before:absolute before:inset-0 before:z-10 before:rounded-xl before:bg-prmOrange before:bg-opacity-90 before:backdrop-blur-[1px]"
      )}
    >
      <div className="flex h-full flex-col items-center justify-center gap-3 p-4 px-4 xl:justify-between">
        <img
          className={classNames(
            "absolute inset-0 h-full w-full rounded-xl border object-cover grayscale"
          )}
          src={cardImageUrl}
          alt={`picture_of_a_${data.en.title}`}
        />
        <div className="relative z-20 flex flex-col items-center justify-center text-center">
          <div className="flex h-24 w-24 items-center justify-center rounded-full bg-white p-3">
            {data.icon}
          </div>
          <h4
            className={classNames(
              "mt-3 text-white",
              isArm ? "font-helv text-2xl lg:text-lg" : "text-2xl font-semibold lg:text-lg"
            )}
          >
            {title}
          </h4>
          <h4 className="text-sm font-extralight text-white lg:hidden xl:block">{paragraph}</h4>
        </div>
        <div
          className={classNames(
            "relative z-20 w-full text-base",
            isArm ? "font-helv" : "font-bold"
          )}
        >
          <div className="hidden w-full flex-col items-center justify-center gap-2  xl:flex">
            <button
              onClick={onPhoneCall}
              className="w-full rounded-full bg-white px-3 py-1 uppercase"
            >
              {phoneActionButton}
            </button>
            <button
              onClick={onEmailSend}
              className="w-full rounded-full bg-prmYellow px-3 py-1 uppercase hover:bg-opacity-90"
            >
              {mailActionButton}
            </button>
          </div>
          <div className="flex w-full items-center justify-center gap-2 xl:hidden">
            <button onClick={onPhoneCall} className="rounded-full bg-white px-3 py-1">
              <PhoneIcon className="h-10 w-10" />
            </button>
            <button
              onClick={onEmailSend}
              className="rounded-full bg-prmYellow px-3 py-1 hover:bg-opacity-90"
            >
              <EnvelopeIcon className="h-10 w-10" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerCard;
