import { Alert } from "react-bootstrap";
import { MdError } from "react-icons/md";

function Error({ errorMessage = "Oops, Sorry! You got an Error!" }) {
  return (
    <Alert variant="danger" className="d-flex justify-content-center">
      <Alert.Heading className="flex items-center justify-center gap-2">
        <MdError /> <span className="text-sm">{errorMessage}</span>
      </Alert.Heading>
    </Alert>
  );
}

export default Error;
