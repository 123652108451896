import useApi from "../../../axios/useAxios";
import { handleFormCloseLanguage, handleFormConfirmLanguage } from "../../Programs/content.js";
import Error from "../../Reusable/Error.jsx";
import SmallLoader from "../../Reusable/SmallLoader.js";
import { partnerContent } from "../content.js";
import { getPartnerContext } from "../index.jsx";
import { useAtomValue } from "jotai";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { localeAtom } from "utils/locale/state";

function NewPartnerModal(props) {
  const language = useAtomValue(localeAtom);
  const getPartners = useContext(getPartnerContext);
  const editPartner = props.partner;

  const method = editPartner ? "put" : "post";
  const endpoint = editPartner ? `/partners/${editPartner._id}` : `/partners`;

  const initialState = {
    name_am: editPartner ? editPartner.name_am : "",
    name_de: editPartner ? editPartner.name_de : "",
    name_en: editPartner ? editPartner.name_en : "",
    url: editPartner ? editPartner.url : "",
    isPartner: editPartner ? editPartner.isPartner : false,
    avatar: editPartner ? editPartner.avatar : {}
  };

  const { navHeaderText } = partnerContent[language];

  const [partner, setPartner] = useState(initialState);
  const [image, setImage] = useState(null);
  const { error, loading, axiosFetch } = useApi();

  const { name_am, name_de, name_en } = partner;

  const isFormValid = name_am !== "" && name_de !== "" && name_en !== "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axiosFetch({
      method,
      url: endpoint,
      requestConfig: {
        data: partner
      }
    });
    if (data && image !== null) {
      const formData = new FormData();
      formData.append("avatar", image);

      partner.avatar.url &&
        (await axiosFetch({
          method: "post",
          url: `/partners/${data._id}/delete-avatar`
        }));

      await axiosFetch({
        method: "post",
        url: `/partners/${data._id}/avatar`,
        requestConfig: {
          data: formData
        }
      });
    }
    if (!error && !loading) {
      getPartners();
      setPartner(initialState);
      setImage(null);
      props.onHide();
    }
  };

  const handleClose = () => {
    setPartner(initialState);
    setImage(null);
    props.onHide();
  };

  useEffect(
    () => {
      setPartner(initialState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editPartner]
  );

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title> {navHeaderText} </Modal.Title>
      </Modal.Header>
      {error && <Error />}
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              required
              type="text"
              placeholder="Գործընկերոջ անվանումը"
              value={partner.name_am}
              onChange={(e) => setPartner({ ...partner, name_am: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="text"
              placeholder="Name des Partners"
              value={partner.name_de}
              onChange={(e) => setPartner({ ...partner, name_de: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="text"
              placeholder="Partners Name"
              value={partner.name_en}
              onChange={(e) => setPartner({ ...partner, name_en: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Url"
              value={partner.url}
              onChange={(e) => setPartner({ ...partner, url: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="partner"
              checked={partner.isPartner}
              onChange={(e) => setPartner({ ...partner, isPartner: e.target.checked })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control type="file" size="sm" onChange={(e) => setImage(e.target.files[0])} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{handleFormCloseLanguage(language)}</Button>
        {loading ? (
          <SmallLoader color="white" />
        ) : (
          <Button type="submit" disabled={!isFormValid} onClick={handleSubmit}>
            {handleFormConfirmLanguage(language)}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default NewPartnerModal;
