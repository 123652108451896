import useAxios from "../../../axios/useAxios";
import { FormInput, FormLabel, FormTextArea, InputWrapper } from "../../UI/InputElements";
import FormButton from "./FormButton";
import { contactDetailsText } from "./content";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { localeAtom } from "utils/locale/state";

const ContactDetails = () => {
  const initialValue = { name: "", email: "", text: "" };

  //Local State
  const [message, setMessage] = useState(initialValue);
  const [isSent, setIsSent] = useState(false);

  //Global State
  const language = useAtomValue(localeAtom);

  const { form: { name, email, text } = {} } = contactDetailsText(language);
  const { error, loading, axiosFetch } = useAxios();

  const toReset = () => {
    setMessage(initialValue);
    setIsSent(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMessage((prevMessage) => ({ ...prevMessage, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = await axiosFetch({
      method: "post",
      url: "/send-email",
      requestConfig: {
        data: message
      }
    });

    if (data) {
      setMessage(initialValue);
      setIsSent(true);
    }
  };

  useEffect(
    () => {
      toReset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <form className="flex-column flex w-full items-center gap-3" onSubmit={handleSubmit}>
      <InputWrapper>
        <FormLabel label={name} forInput={"name"}>
          <FormInput
            id={"name"}
            name={"name"}
            type={"text"}
            callback={handleChange}
            value={message.name}
            required
          />
        </FormLabel>
      </InputWrapper>
      <InputWrapper>
        <FormLabel label={email} forInput={"email"}>
          <FormInput
            id={"email"}
            name={"email"}
            type={"email"}
            callback={handleChange}
            value={message.email}
            required
          />
        </FormLabel>
      </InputWrapper>
      <InputWrapper>
        <FormLabel label={text} forInput={"text"}>
          <FormTextArea
            id={"text"}
            name={"text"}
            callback={handleChange}
            value={message.text}
            required
          />
        </FormLabel>
      </InputWrapper>
      <FormButton loading={loading} error={error} isReady={isSent} reset={toReset} />
    </form>
  );
};

export default ContactDetails;
