import { transformedImageUrl } from "../../../utils";
import { dateExtractor } from "../../../utils/dates";
import LightBoxModal from "../../LightBoxModal";
import { lightBoxImgUrlAtom, lightBoxModalAtom } from "../../LightBoxModal/state";
import ShortParagraph from "../ShortParagraph";
import SpinnerLoader from "../Spinner";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Carousel, Container } from "react-bootstrap";
import { localeAtom } from "utils/locale/state";

function SinglePostWrapper({ data, isLoading, category }) {
  const language = useAtomValue(localeAtom);
  const [isLightBoxOpen, setIsLightBoxOpen] = useAtom(lightBoxModalAtom);
  const setImageUrl = useSetAtom(lightBoxImgUrlAtom);

  if (isLoading) {
    return <SpinnerLoader />;
  }

  const lightBoxToOpen = (url) => {
    if (isLightBoxOpen) return;

    setImageUrl(url);
    setIsLightBoxOpen(true);
  };

  const articleDate = (data && data?.date) || data?.createdAt;
  const isGallery = data?.pictures?.length > 1;
  const isArticle = category === "articles";
  return (
    <Container className="flex flex-col md:p-4">
      <div className="aspect-square w-full overflow-hidden p-2 shadow-md outline md:aspect-[11/4] md:p-4">
        <Carousel
          interval={null}
          variant="dark"
          indicators={isGallery}
          controls={false}
          className="flex h-full items-center justify-center"
        >
          {data?.pictures?.length > 0 ? (
            data?.pictures?.map((picture, index) => (
              <Carousel.Item key={index}>
                <div className="h-full w-full md:aspect-[11/4]">
                  <img
                    onClick={() => lightBoxToOpen(picture?.url)}
                    className="aspect-square h-full w-full object-contain md:aspect-video"
                    src={transformedImageUrl({ url: picture?.url, width: 1000, ratio: "16:9" })}
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              </Carousel.Item>
            ))
          ) : data?.cover?.url ? (
            <div className="mx-auto h-full w-full border">
              <img
                className="aspect-square h-full w-full object-contain p-2 md:aspect-video"
                src={transformedImageUrl({ url: data?.cover?.url, isLarge: true, ratio: "16:9" })}
                onClick={() => lightBoxToOpen(data?.cover?.url)}
                alt="slide cover"
              />
            </div>
          ) : (
            <div />
          )}
        </Carousel>
        <LightBoxModal />
      </div>
      <div className="mt-4 flex flex-col items-start justify-center gap-2 text-prmDark">
        {isArticle && (
          <span className="rounded-sm text-[12px] font-light uppercase tracking-wider sm:text-sm">
            {dateExtractor(articleDate, language)}
          </span>
        )}
        <h1 className="font-light sm:text-2xl">{data && data[`title_${language}`]}</h1>
      </div>
      <ShortParagraph data={data && data[`description_${language}`]} language={language} />
    </Container>
  );
}

export default SinglePostWrapper;
