export const videoWrapperText = {
  am: {
    header: "Վերնատուն․ «Բարեգործը պիտի գնա մինչեւ վերջ՝ առանց երախտիքի խոսք ակնկալելու»",
    paragraph: "Դիտեք մեր տնօրեն՝ Մովսես Պողոսյանի հարցազրույցը, Հայաստանի Հանրային Ռադիոյի եթերում"
  },
  en: {
    header: `Vernatun. "The benefactor must go all the way, without entertaining the idea of regret"`,
    paragraph:
      "Watch our director, Movses Poghosyan's interview, on the air of Armenian Public Radio"
  },
  de: {
    header: `Vernatun. "Der Wohltäter muss bis zum Ende gehen, ohne den Gedanken an Bedauern zu hegen."`,
    paragraph:
      "Sehen Sie sich das Interview unseres Direktors, Movses Poghosyan, im Programm des Armenischen Öffentlichen Radios an."
  }
};
