import { CutleryIcon } from "components/icons/cutlery-icon";
import { HandsUpIcon } from "components/icons/hands-up-icon";
import { ProBonoIcon } from "components/icons/pro-bono-icon";
import { TShirtIcon } from "components/icons/tshirt-icon";

export const cardContent = [
  {
    icon: <HandsUpIcon />,
    cover:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1731860965/HouseOfHope/statics/volunteers/vol-1_lprw6i.jpg",
    mobileNumber: "123-343-234",
    en: {
      title: "Volunteering",
      paragraph: "Become a Volunteer",
      phoneActionButton: "Call Us",
      mailActionButton: "Email Us"
    },
    de: {
      title: "Freiwilligenarbeit",
      paragraph: "Werden Sie freiwillig",
      phoneActionButton: "Rufen Sie uns an",
      mailActionButton: "E-Mail senden"
    },
    am: {
      title: "Կամավորություն",
      paragraph: "Դարձեք կամավոր",
      phoneActionButton: "Զանգահարել",
      mailActionButton: "Ուղարկել նամակ"
    }
  },
  {
    icon: <ProBonoIcon />,
    cover:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1731860964/HouseOfHope/statics/volunteers/vol-pro-bono-2_pyexw9.jpg",
    mobileNumber: "123-343-235",
    en: {
      title: "Pro-Bono",
      paragraph: "Skill-Based Volunteering",
      phoneActionButton: "Call Us",
      mailActionButton: "Email Us"
    },
    de: {
      title: "Pro-Bono",
      paragraph: "Fähigkeitsbasierte Freiwilligenarbeit",
      phoneActionButton: "Rufen Sie uns an",
      mailActionButton: "E-Mail senden"
    },
    am: {
      title: "Պրո Բոնո",
      paragraph: "Հմտություն վրա հիմնված կամավորություն",
      phoneActionButton: "Զանգահարել",
      mailActionButton: "Ուղարկել նամակ"
    }
  },
  {
    icon: <TShirtIcon />,
    cover:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1731860964/HouseOfHope/statics/volunteers/vol-pro-bono_wlkddg.jpg",
    mobileNumber: "123-343-236",
    en: {
      title: "Donating Clothes",
      paragraph: "Donate clothes and add warmth to lives",
      phoneActionButton: "Call Us",
      mailActionButton: "Email Us"
    },
    de: {
      title: "Kleiderspende",
      paragraph: "Spenden Sie Kleidung und schenken Sie Wärme fürs Leben",
      phoneActionButton: "Rufen Sie uns an",
      mailActionButton: "E-Mail senden"
    },
    am: {
      title: "Նվիրաբերել Հագուստ",
      paragraph: "Նվիրեք հագուստ՝ ջերմություն ավելացնելու համար",
      phoneActionButton: "Զանգահարել",
      mailActionButton: "Ուղարկել նամակ"
    }
  },
  {
    icon: <CutleryIcon />,
    cover:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1731860964/HouseOfHope/statics/volunteers/vol-food_htohau.jpg",
    mobileNumber: "123-343-237",
    en: {
      title: "Donating Food",
      paragraph: "Donate food and bring hope to the table.",
      phoneActionButton: "Call Us",
      mailActionButton: "Email Us"
    },
    de: {
      title: "Lebensmittelspende",
      paragraph: "Spenden Sie Essen und bringen Sie Hoffnung auf den Tisch",
      phoneActionButton: "Rufen Sie uns an",
      mailActionButton: "E-Mail senden"
    },
    am: {
      title: "Նվիրաբերել Սնունդ",
      paragraph: "Նվիրեք սնունդ՝ սեղաններին հույս բերելու համար",
      phoneActionButton: "Զանգահարել",
      mailActionButton: "Ուղարկել նամակ"
    }
  }
];
