import useApi from "../../../axios/useAxios";
import { donationTranslatedText } from "../content";
import { currencyAtom, defaultValuesAtom, donorDetailsAtom, errorAtom } from "../state";
import CustomValues from "./CustomValues";
import DefaultValues from "./DefaultValues";
import DonorDetails from "./DonorDetails";
import { HeartIcon } from "@heroicons/react/24/solid";
import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

function DonationForm() {
  const { axiosFetch } = useApi();

  const locale = useAtomValue(localeAtom);
  const isArm = locale === "am";
  const { form: { donateButton, title } = {} } = donationTranslatedText[locale];

  //Local State
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  //Global State
  const amount = useAtomValue(defaultValuesAtom);
  const setErrorMessage = useSetAtom(errorAtom);
  const { code } = useAtomValue(currencyAtom);
  const { name: fullName, email, isAnonymous, cause } = useAtomValue(donorDetailsAtom);

  const order = {
    fullName,
    email,
    amount,
    anonymous: isAnonymous,
    currency: code,
    cause
  };

  const createOrder = () => {
    return axiosFetch({
      method: "post",
      url: "/orders",
      requestConfig: {
        data: order
      }
    });
  };

  const processPayment = (order) => {
    const { amount, orderNumber, currency } = order;

    return axiosFetch({
      method: "post",
      url: "/payment",
      requestConfig: {
        data: { amount, orderNumber, currency }
      }
    });
  };

  async function handlePayment() {
    if (!amount || !fullName) {
      setErrorMessage("No Value Inserted");
      return undefined;
    }

    // handles heart animation
    setIsButtonClicked(true);

    try {
      const { data } = await createOrder();
      if (data.orderNumber) {
        const {
          data: { formUrl }
        } = await processPayment(data);
        if (formUrl && window !== "undefined") {
          window.location.replace(formUrl);

          // handles heart animation
          await new Promise(() => setTimeout(() => setIsButtonClicked(false), 1000));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="just-between relative flex flex-col rounded-md bg-prmOrange p-[1.2rem] sm:p-[2rem]">
      <div className="h-full">
        <div className="flex justify-start gap-2 py-2 text-white">
          <h3 className="font-num text-lg font-semibold uppercase">{title}</h3>
        </div>
        <form action="get" className="mt-1 flex w-full flex-col items-center gap-1 text-white">
          <DefaultValues />
          <CustomValues />
          <DonorDetails />
        </form>
      </div>
      <button
        className={classNames(
          `mt-2 flex w-fit items-center justify-center gap-2 self-center rounded-full bg-prmYellow p-2 px-5 font-medium uppercase tracking-wide hover:bg-[#f5b83d]`,
          "text-xl",
          isArm ? " font-helv" : "font-num"
        )}
        onClick={handlePayment}
      >
        <HeartIcon className={`h-5 w-5 ${isButtonClicked ? "clicked" : ""}`} />
        {donateButton}
      </button>
    </div>
  );
}

export default DonationForm;
