import useAxios from "../../axios/useAxios";
import { ProjectContext, adminContext } from "../Context";
import DynamicHelmet from "../Helmet/DynamicHelmet";
import EditModal from "../Reusable/Card/Modals/EditModal";
import Error from "../Reusable/Error";
import SinglePostWrapper from "../Reusable/SinglePostWrapper";
import SpinnerLoader from "../Reusable/Spinner";
import ArticleCard from "../UI/Cards/ArticleCard";
import CardContainer from "../UI/Layout/CardContainer";
import { projectContentText } from "./content";
import "./style.css";
import { useAtomValue } from "jotai";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { localeAtom } from "utils/locale/state";

function Projects() {
  const { isAdmin } = useContext(adminContext);
  const language = useAtomValue(localeAtom);

  const [modalShow, setModalShow] = useState(false);
  const { archivedText } = projectContentText[language];

  const { id } = useParams();

  const {
    response: { projects, archived, category, currentProject } = {},
    loading,
    error,
    axiosFetch
  } = useAxios();

  const params = {
    currentId: id,
    limit: 8
  };

  async function fetchProjects() {
    await axiosFetch({
      url: `/projects`,
      params
    });
  }

  useEffect(
    () => {
      fetchProjects();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  if (loading) {
    return <SpinnerLoader />;
  }

  return (
    <ProjectContext.Provider value={fetchProjects}>
      <DynamicHelmet title={"HOH | Projects"} />
      {isAdmin && (
        <div className="admin-tools absolute top-16 z-10 sm:top-20">
          <div
            className="cursor-pointer bg-darkGreen px-2 py-1 text-sm"
            onClick={() => setModalShow(true)}
          >
            Create
          </div>
          <EditModal type="projects" show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
      <SinglePostWrapper data={currentProject} isLoading={loading} category={category} />
      {error && <Error />}
      <Container>
        <CardContainer>
          {projects?.map((post) => (
            <ArticleCard post={post} key={post._id} category={category} />
          ))}
        </CardContainer>
        {/* Create a container for archived cards */}
        {!!archived?.length && (
          <div className="rounded-sm bg-yellow-400 p-2">
            <div className="font-light uppercase tracking-wider text-prmDark">{archivedText}</div>
            <CardContainer>
              {archived?.map((post) => (
                <ArticleCard post={post} key={post._id} category={category} />
              ))}
            </CardContainer>
          </div>
        )}
      </Container>
    </ProjectContext.Provider>
  );
}

export default Projects;
