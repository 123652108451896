import { jwtDecode } from "jwt-decode";
import ls from "localstorage-slim";
import { useEffect } from "react";

const useCheckTokenValidity = ({ isAdmin, setIsAdmin }) => {
  const handleLogout = () => {
    setIsAdmin(false);
    ls.remove("house_admin");
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      const token = ls.get("house_admin");
      if (token) {
        try {
          const decoded = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);
          if (decoded.exp < currentTime) {
            handleLogout();
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          handleLogout();
        }
      }
    };

    checkTokenExpiry();
    // eslint-disable-next-line
  }, [isAdmin]);
};

export default useCheckTokenValidity;
