import ContactDetails from "./ContactDetails";
import { contactDetailsText } from "./content";
import { useAtomValue } from "jotai";
import ContainerTitle from "ui/wrappers/container-title";
import { localeAtom } from "utils/locale/state";

function ContactUs() {
  const language = useAtomValue(localeAtom);

  const { emailTitle, emailContent, phoneTitle, phoneContent, locationTitle, locationContent } =
    contactDetailsText(language);

  const contactDetails = [
    { type: "email", title: emailTitle, detail: emailContent },
    { type: "phone", title: phoneTitle, detail: phoneContent },
    { type: "location", title: locationTitle, detail: locationContent }
  ];

  return (
    <div className="mx-auto max-w-screen-xl p-4 pb-0">
      <ContainerTitle title="contactUs" />
      <div className="flex w-full flex-col-reverse items-start justify-between gap-x-6 border-b py-4 text-prmDark lg:flex-row xl:gap-x-12">
        <div className="mt-[2rem] w-full rounded-md border p-[1px] lg:mt-0">
          <div className="aspect-video bg-white p-1">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.222924561942!2d44.54136507674926!3d40.204103871474686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa2d67d2688d3%3A0xd3c9862fc169ba29!2sHouse%20of%20Hope!5e0!3m2!1sen!2sde!4v1698523800654!5m2!1sen!2sde"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
            />
          </div>
          <div className="p-1 py-3 text-xs sm:text-sm ">
            {contactDetails.map((c, i) => (
              <div key={`${i}-contact-detail`} className="flex justify-between">
                <span className="font-light uppercase tracking-widest">{c.title}</span>
                <span className="font-light">{c.detail}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <ContactDetails />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
