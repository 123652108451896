import { homeContent } from "./content";
import { useAtomValue } from "jotai";
import { classNames, transformedImageUrl } from "utils";
import { localeAtom } from "utils/locale/state";

const HeroElement = () => {
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  const {
    jumbotron: { mainTitle },
    greetings: { header } = {}
  } = homeContent[language];

  return (
    <div
      className={classNames(
        "before:absolute before:inset-0 before:z-10 before:bg-[#68676770]",
        "relative flex aspect-[3/2] max-h-[630px] w-full flex-col items-center justify-center sm:aspect-[5/2]",
        isArm ? " font-art" : ""
      )}
    >
      <img
        src={transformedImageUrl({
          url: "https://res.cloudinary.com/di8iypeft/image/upload/v1731277447/HouseOfHope/statics/cover_avtgci.jpg",
          width: 1000
        })}
        alt="hero-image"
        className="absolute inset-0 h-full w-full object-cover object-[0_10%] grayscale filter"
      />

      <div className="relative z-10 flex w-full max-w-screen-xl flex-col items-start justify-center gap-1 p-4 lg:gap-2">
        <p className="w-fit rounded-xl text-xl tracking-wide text-white sm:text-lg lg:text-3xl">
          {mainTitle}
        </p>
        <h1 className="text-4xl font-bold uppercase text-white lg:text-6xl">{header}</h1>
      </div>
    </div>
  );
};

export default HeroElement;
