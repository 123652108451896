import { useAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { localeAtom } from "utils/locale/state";

function LanguageDropdown() {
  const dropdownRef = useRef(null);

  //local states
  const [isOpen, setIsOpen] = useState(false);

  //global state
  const [language, setLanguage] = useAtom(localeAtom);

  const chooseLanguage = useCallback(
    (flag) => {
      setLanguage(flag);

      if (isOpen) {
        setIsOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  );

  // handles the dropdown close function if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative z-50 flex justify-start text-black">
      <span
        onClick={() => setIsOpen(!isOpen)}
        className={`fi fi-${language === "en" ? "gb" : language} cursor-pointer`}
      ></span>
      {isOpen && (
        <div className="absolute -left-4 top-8 flex flex-col gap-3 rounded-b-md bg-white p-3 pt-2 shadow-sm">
          {["am", "de", "en"].map((flag, index) => {
            if (language !== flag) {
              return (
                <span
                  onClick={() => chooseLanguage(flag)}
                  key={`${flag}-${index}`}
                  className={`fi fi-${flag === "en" ? "gb" : flag} cursor-pointer`}
                ></span>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default LanguageDropdown;
