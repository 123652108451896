import { classNames } from "utils";

export default function CardContainer({ children }) {
  return (
    <div
      className={classNames(
        "mx-auto my-4 grid w-full gap-x-4 gap-y-10 lg:mx-0",
        "grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4"
      )}
    >
      {children}
    </div>
  );
}
