export const AvatarBlob = () => {
  return (
    <svg
      className="absolute inset-0 -z-10 h-full w-full"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#F74F21"
        d="M47.3,-51C55.6,-38.9,52.9,-19.4,51.4,-1.6C49.8,16.3,49.4,32.7,41,43.4C32.7,54.1,16.3,59.2,2.2,57C-11.9,54.8,-23.8,45.2,-37.5,34.5C-51.1,23.8,-66.5,11.9,-66.8,-0.3C-67.1,-12.5,-52.3,-25,-38.6,-37.1C-25,-49.2,-12.5,-61,3.5,-64.5C19.4,-68,38.9,-63.2,47.3,-51Z"
        transform="translate(100 100) scale(1.5)"
      />
    </svg>
  );
};
