export const prioDonationText = {
  artsakhFundraiser: {
    imageUrl:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1732022081/HouseOfHope/statics/artsakh-support_gmzty5.jpg",
    color: "#F74F21",
    en: {
      projectTitle: "Fundraiser for the Forcibly Displaced Artsakh Armenians",
      projectDescription:
        "Armenian women from Artsakh are provided with cooking classes, and masterclasses are organized",
      callToAction: "Donate to support displaces families from Artsakh",
      donationText: "Donate Now",
      badge: "Urgent Donation Call"
    },
    de: {
      projectTitle: "Spendenaktion für die zwangsvertriebenen Armenier aus Arzach",
      projectDescription:
        "Armenische Frauen aus Artsakh erhalten Kochkurse, und es werden Meisterklassen organisiert",
      callToAction: "Unterstützung an die gewaltsam vertriebenen armenischen Familien aus Artsakh",
      donationText: "Jetzt Spenden",
      badge: "Dringender Spendenaufruf"
    },
    am: {
      projectTitle: "Օգնություն Արցախից բռնի տեղահանված արցախահայերին",
      projectDescription:
        "Արցախահայ կանանց տրամադրվում են խոհարարական դասընթացներ, կազմակերպվում են վարպետաց դասեր։",
      callToAction: "Աջակցել բռնի տեղահանված արցախահայ ընտանիքներին",
      donationText: "Նվիրաբերել",
      badge: "Շտապ հավաքագրում"
    }
  },
  charityKitchen: {
    imageUrl:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1648550506/HouseOfHope/vghgihiengvwvmwmi3yq.jpg",
    color: "#0033A0",
    en: {
      projectTitle:
        "Provision of care for the elderly and (or) disabled individuals, and organization of food for those in need.",
      projectDescription:
        "Socially vulnerable residents of Yerevan, including families forcibly displaced from Artsakh, receive daily hot and calorie-dense meals.",
      callToAction: "Donate to support for families in need",
      donationText: "Donate Now"
    },
    de: {
      projectTitle:
        "Bereitstellung von Pflege für ältere Menschen und (oder) Menschen mit Behinderungen sowie Organisation von Nahrung für Bedürftige.",
      projectDescription:
        "Die sozial stark benachteiligten Bewohner von Jerewan, einschließlich der gewaltsam aus Artsakh vertriebenen Familien, erhalten täglich warme und kalorienreiche Mahlzeiten.",
      callToAction: "Unterstützung an die Familien in Not",
      donationText: "Jetzt Spenden"
    },
    am: {
      projectTitle:
        "Տարեցների և (կամ) հաշմանդամ անձանց խնամքի տրամադրում և անապահով անձանց սննդի կազմակերպում",
      projectDescription:
        "Երևան քաղաքի սոցիալապես խիստ անապահով բնակիչները, այդ թվում նաև Արցախից բռնի տեղահանված ընտանիքները ստանում են ամենօրյա տաք և կալորիապես բարձր սնունդ։ ",
      callToAction: "Աջակցել կարիքի մեջ գտնվող ընտանքիներին",
      donationText: "Նվիրաբերել",
      badge: "Շտապ հավաքագրում"
    }
  },
  homeCare: {
    imageUrl:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1729640070/HouseOfHope/statics/396953569_349492374416743_559671008794638731_n_rw9zgu.jpg",
    color: "#FFAB00",
    en: {
      projectTitle: "Home Care",
      projectDescription:
        "In Yerevan, 25 solitary elderly with motion issues are given social-psychological support.",
      callToAction: "Donate to aid the elderly who reside alone.",
      donationText: "Donate Now"
    },
    de: {
      projectTitle: "Hauspflege",
      projectDescription:
        "25 älteren Menschen in Eriwan, die Bewegungsprobleme haben und allein sind, wird sozialpsychologische Hilfe geleistet",
      callToAction: "Unterstützung an die alleinstehenden alten Leuten",
      donationText: "Jetzt Spenden"
    },
    am: {
      projectTitle: "Տնային խնամք",
      projectDescription:
        "Սոցիալ-հոգեբանական օգնություն է տրամադրվում Երևան քաղաքում բնակվող 25 հենաշարժողական խնդիրների ունեցող, միայնակ տարեցներին:",
      callToAction: "Աջակցել միայնակ տարեցներին",
      donationText: "Նվիրաբերել"
    }
  },
  childrenFood: {
    imageUrl:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1697695701/HouseOfHope/file_brttc8.jpg",
    color: "#FF6E18",
    en: {
      projectTitle: "Children food",
      projectDescription:
        "Over 100 children attending kindergartens in border-near villages of Tavush region receive daily meals.",
      callToAction: "Donate to help children in border regions.",
      donationText: "Donate Now"
    },
    de: {
      projectTitle: "Kinderspeisung",
      projectDescription:
        "Es wird die tägliche Mahlzeit für insgesamt 106 Kinder bereitgestellt, die Kindergärten in den grenznahen Ortschaften der Region Tavush besuchen",
      callToAction: "Unterstützung an die Kinder in Grenzgebieten",
      donationText: "Jetzt Spenden"
    },
    am: {
      projectTitle: "Մանկական սնունդ",
      projectDescription:
        "Ապահովվում է Տավուշի մարզի սահմանամերձ բնակավայրերի մանկապարտեզներ հաճախող ընդհանուր առմամբ 106 երեխաների ամենօրյա սնունդը։",
      callToAction: "Աջակցել սահմանամերձ բնակավայրերի երեխաներին",
      donationText: "Նվիրաբերել"
    }
  },
  healthyChildhood: {
    imageUrl:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1697695232/HouseOfHope/file_e6ohx0.jpg",
    color: "#4D77D1",
    en: {
      projectTitle: "Hopeful and Healthy Childhood",
      projectDescription:
        "Social-psychological care is provided to over 70 children of Artsakh and Syrian-Armenian refugee families in Yerevan.",
      callToAction: "Support displaced children from Artsakh and Syria with your donation.",
      donationText: "Donate Now"
    },
    de: {
      projectTitle: "Hoffnungsvolle und gesunde Kindheit",
      projectDescription:
        "Insgesamt 70 Kindern aus artsakh-armenischen und syrisch-armenischen Flüchtlingsfamilien in Jerewan werden sozialpsychologische Unterstützung geleistet",
      callToAction: "Unterstützung an die vertriebenen Kinder aus Artsakh und Syrien",
      donationText: "Jetzt Spenden"
    },
    am: {
      projectTitle: "Հույսով լի և առողջ մանկություն",
      projectDescription:
        "Սոցիալ-հոգեբանական օգնություն է տրամադրվում Երևան քաղաքում բնակվող արցախայ և սիրիահայ փախստական ընտանիքների ընդհանուր առմամբ 70 երեխաներին։",
      callToAction: "Աջակցել արցախահայ և սիրիահայ փախստական երեխաներին",
      donationText: "Նվիրաբերել"
    }
  },
  socialAid: {
    imageUrl:
      "https://res.cloudinary.com/di8iypeft/image/upload/v1732022804/HouseOfHope/statics/IMG_6086_sg2qzp.jpg",
    color: "#FFC225",
    en: {
      projectTitle: "Social immediate aid",
      projectDescription:
        "Essential food and non-food aid is being given to over 600 extremely needy families in RA regions and Yerevan city.",
      callToAction: "Donate to aid families in extreme need.",
      donationText: "Donate Now"
    },
    de: {
      projectTitle: "Soziale Nothilfe",
      projectDescription:
        "Insgesamt 600 extrem bedürftigen Familien in den Regionen der RA und in der Stadt Eriwan wird Lebensmittel- und Non-Food-Hilfe geleistet.",
      callToAction: "Unterstützung an die Familien in extremer Not",
      donationText: "Jetzt Spenden"
    },
    am: {
      projectTitle: "Սոցիալական շտապօգնություն",
      projectDescription:
        "Պարենային և ոչ պարենային օգնություն է տրամադրվում ՀՀ մարզերում և Երևան  քաղաքում բնակվող ընդհանուր առմամբ 600 ծայրահեղ կարիքավոր ընտանիքներին",
      callToAction: "Աջակցել ծայրահեղ կարիքավոր ընտանքիներին",
      donationText: "Նվիրաբերել"
    }
  }
};
