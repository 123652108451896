import Story from "components/AboutUs/Story";
import Team from "components/AboutUs/Team";
import "components/AboutUs/style.css";
import BoardOfTrusties from "components/AboutUs/trusties";
import DynamicHelmet from "components/Helmet/DynamicHelmet";
import PageWrapper from "ui/wrappers/page-wrapper";

function AboutUs() {
  return (
    <PageWrapper>
      <DynamicHelmet title={"HOH | About Us"} />
      <Story />
      <BoardOfTrusties />
      <Team />
    </PageWrapper>
  );
}

export default AboutUs;
