import { axiosInstance } from "./axiosInstance";
import { useEffect, useState } from "react";

const useAxios = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const axiosFetch = async (configObj) => {
    const { method = "get", url, params = {}, requestConfig = {} } = configObj;

    const isFormData = requestConfig.data instanceof FormData;
    const headers = isFormData ? { "Content-Type": "multipart/form-data" } : {};
    // Clear any previous error
    setError(null);

    try {
      setLoading(true);

      // Create a new AbortController instance for each request
      const ctrl = new AbortController();

      // Execute the request
      const res = await axiosInstance.request({
        method: method.toLowerCase(),
        url: url,
        params: params,
        headers: headers,
        data: requestConfig.data,
        // AbortController's signal should be in the Axios config, not in the data
        signal: ctrl.signal
      });

      // Set the response data to state
      setResponse(res.data);

      return res;
    } catch (err) {
      // Set the error message to state
      setError(err.message);
    } finally {
      // Set loading to false after the request completes or fails
      setLoading(false);
    }
  };

  useEffect(() => {
    // Create a new AbortController instance for handling useEffect cleanup
    const ctrl = new AbortController();

    // Cleanup function to abort any ongoing requests when the component unmounts
    return () => ctrl.abort();
  }, []); // Empty dependency array to run only on mount and unmount

  return { response, error, loading, axiosFetch };
};

export default useAxios;
