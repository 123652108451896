import AdminTools from "../../Reusable/AdminTools";
import DeletePartnerModal from "./DeletePartnerModal";
import NewPartnerModal from "./NewPartnerModal";
import { useState } from "react";
import { Col } from "react-bootstrap";

function NewPartner({ partner, admin, language }) {
  const [modalShow, setModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  return (
    <Col xs={4} sm={3} lg={2} className="single-partner">
      {partner.avatar ? (
        <div className="single-partner-image" onClick={() => window.open(`${partner?.url}`)}>
          <img src={partner?.avatar?.url} alt="partner-logo" />
        </div>
      ) : (
        <div className="single-partner-text">
          <span>{partner[`name_${language}`]}</span>
        </div>
      )}
      <AdminTools
        showAdminModal={(v) => setModalShow(v)}
        showDeleteModal={(v) => setDeleteModalShow(v)}
        isAdmin={admin}
      />
      <DeletePartnerModal
        partner={partner}
        show={deleteModalShow}
        language={language}
        onHide={() => setDeleteModalShow(false)}
      />
      <NewPartnerModal partner={partner} show={modalShow} onHide={() => setModalShow(false)} />
    </Col>
  );
}

export default NewPartner;
