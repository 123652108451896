import { useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";

function LazyLoadYouTube({ videoId }) {
  const [showVideo, setShowVideo] = useState(false);
  const thumbnailSrc = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

  if (showVideo) {
    return (
      <iframe
        className="aspect-video w-full flex-1 rounded-lg"
        src={iframeSrc}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }

  return (
    <div className="relative" onClick={() => setShowVideo(true)}>
      <img src={thumbnailSrc} alt="Click to play video" className="rounded-lg" />
      <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center">
        <AiFillPlayCircle className="h-16 w-16 cursor-pointer fill-white hover:fill-gray-100 md:h-28 md:w-28" />
      </div>
    </div>
  );
}

export default LazyLoadYouTube;
