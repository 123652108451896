import { classNames } from "../../../utils";
import { prioDonationText } from "./content";
import "components/Donate/style.css";
import { SwiperSlide } from "swiper/react";
import DonateCard from "ui/cards/donate-card";
import SwiperCarousel from "ui/swipers/swiper-carousel";
import ContainerWrapper from "ui/wrappers/container-wrapper";

function PrioDonations() {
  return (
    <ContainerWrapper>
      <div
        className={classNames(
          "relative flex w-full items-center justify-center text-center lg:hidden"
        )}
      >
        <SwiperCarousel slidesPerView={2}>
          {Object.keys(prioDonationText).map((donation) => {
            const content = prioDonationText[donation];
            return content ? (
              <SwiperSlide key={content.color}>
                <DonateCard donation={content} />
              </SwiperSlide>
            ) : null;
          })}
        </SwiperCarousel>
      </div>
      <div
        className={classNames(
          "relative z-10 hidden w-full flex-row flex-wrap items-center justify-center gap-[0.5rem] text-center lg:flex lg:gap-[0.8rem]"
        )}
      >
        {Object.keys(prioDonationText).map((donation) => {
          const content = prioDonationText[donation];
          return content ? <DonateCard donation={content} key={content.color} /> : null;
        })}
      </div>
    </ContainerWrapper>
  );
}

export default PrioDonations;
