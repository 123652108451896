import { Container, Spinner } from "react-bootstrap";

function SpinnerLoader() {
  return (
    <Container
      style={{ height: "100vh" }}
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner animation="border" role="status" variant="dark">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
}

export default SpinnerLoader;
