import { classNames, getDisplayValue } from "../../../utils";
import { currencyAtom, defaultValuesAtom, errorAtom } from "../state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

const defaultAmount = {
  eur: [10, 30, 50, 100, 300, 500],
  amd: [5000, 10000, 25000, 50000, 120000, 250000],
  usd: [10, 30, 50, 100, 300, 500],
  rub: [100, 500, 1000, 5000, 10000, 20000]
};

function DefaultValues() {
  const [selected, setSelected] = useAtom(defaultValuesAtom);
  const setErrorMessage = useSetAtom(errorAtom);
  const currency = useAtomValue(currencyAtom);
  const currentCurrency = currency.text;

  const setDefaultValue = (e) => {
    e.preventDefault();
    const cleanInputValue = e.target.value.replace(/,/g, "");
    setSelected(cleanInputValue);
    setErrorMessage(false);
  };

  return (
    <div id="default-values" className="flex w-full flex-wrap gap-2">
      {defaultAmount[currentCurrency].map((value, i) => (
        <div className="relative max-w-[31.6%]" key={`amount_${i}`}>
          <input
            className={classNames(
              "text-md h-full w-full cursor-pointer rounded-md border-none p-2 text-center font-num font-bold text-prmDark outline-none",
              parseInt(selected) === parseInt(value) ? "bg-prmYellow" : ""
            )}
            type="text"
            value={getDisplayValue(value)}
            onClick={setDefaultValue}
            readOnly
          />
        </div>
      ))}
    </div>
  );
}

export default DefaultValues;
