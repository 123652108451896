import "swiper/css";
import "swiper/css/bundle";
import { A11y, Autoplay, FreeMode, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

const SwiperCarousel = ({
  children,
  slidesPerView = 5,
  loop = false,
  autoplay = false,
  freemode = false
}) => {
  return (
    <Swiper
      loop={loop}
      slidesPerView={slidesPerView}
      spaceBetween={30}
      pagination={{ clickable: true }}
      freeMode={{ enabled: freemode, momentum: false }}
      {...(freemode && { speed: 5000 })}
      {...(autoplay && {
        autoplay: { delay: freemode ? 1 : 5000, disableOnInteraction: true }
      })}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        640: {
          slidesPerView: slidesPerView < 2 ? slidesPerView : 2,
          spaceBetween: 10
        },
        "@1.10": {
          slidesPerView: slidesPerView < 3 ? slidesPerView : 3,
          spaceBetween: 20
        },
        "@1.50": {
          slidesPerView: slidesPerView < 4 ? slidesPerView : 4,
          spaceBetween: 30
        }
      }}
      modules={[Autoplay, FreeMode, Pagination, A11y]}
      onAutoplayStop={(event) =>
        setTimeout(() => {
          event?.autoplay?.start();
        }, 5000)
      }
    >
      {children}
    </Swiper>
  );
};

export default SwiperCarousel;
