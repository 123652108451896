import { classNames } from "../../../utils";

export const InputWrapper = ({ children }) => {
  return (
    <div
      className={classNames(
        "custom-values flex w-full justify-between rounded-md border bg-white px-2 shadow-sm"
      )}
    >
      {children}
    </div>
  );
};

export const FormLabel = ({ isError, label, forInput, children }) => {
  return (
    <label
      htmlFor={forInput}
      className={classNames(
        "w-full py-1 text-[0.7rem] tracking-widest text-inherit outline-none",
        isError ? "text-red-600" : ""
      )}
    >
      {label?.toUpperCase()}
      {children}
    </label>
  );
};

export const FormInput = ({ name, type, id, callback, value, required = false }) => {
  return (
    <input
      id={id}
      name={name}
      className="no-spinner ml-1 h-12 w-full p-1 text-lg font-extralight text-prmDark outline-none"
      type={type}
      onChange={callback}
      value={value}
      required={required}
    />
  );
};

export const FormCheckBox = ({ name, type, id, callback, value, required = false }) => {
  return (
    <input
      id={id}
      name={name}
      className="no-spinner relative z-20 ml-1 h-6 w-6 cursor-pointer border-2 p-1 outline-none"
      type={type}
      onChange={callback}
      checked={value}
      required={required}
    />
  );
};

export const FormTextArea = ({ name, rows = 5, id, callback, value, required = false }) => {
  return (
    <textarea
      id={id}
      className="no-spinner ml-1 w-full p-1 text-lg text-prmDark outline-none"
      rows={rows}
      name={name}
      onChange={callback}
      value={value}
      required={required}
    />
  );
};

export const FormSelect = ({ selections, callback, currentCause }) => {
  if (!selections?.length) return null;

  return (
    <select
      onChange={callback}
      className="max-w-7 h-12 w-full cursor-pointer appearance-none overflow-hidden text-sm font-light text-prmDark outline-none"
      name="cause"
      id="cause"
    >
      <option value={currentCause}>{currentCause}</option>
      {selections.map((item, i) => (
        <option key={`${item}-${i}`} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};
