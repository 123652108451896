import "./style.css";
import { Watch } from "react-loader-spinner";

function Loader() {
  return (
    <div className="loader">
      <Watch height="30" width="30" color="grey" ariaLabel="loading" />
    </div>
  );
}

export default Loader;
