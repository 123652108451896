import DonationButton from "../../Home/PrioDonations/DonationButton";
import EnLogo from "../../UI/Logos/EnLogo";
import LanguageDropDown from "./LanguageDropdown";
import { headerNavText } from "./content";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  const { aboutUs, patrons, news, projects, contactUs } = headerNavText(language);

  const navigation = [
    { name: aboutUs, href: "/about-us" },
    { name: patrons, href: "/partners" },
    { name: news, href: "/news" },
    { name: projects, href: "/projects" },
    { name: contactUs, href: "#contactUs" }
  ];

  const navigateTo = (href) => {
    if (!href.includes("#")) {
      navigate(href);
    } else {
      animateScroll.scrollToBottom({ duration: 200, smooth: true });
    }

    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <header className="sticky top-0 z-50 w-full bg-white shadow-sm">
      <nav
        className="sticky mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-3 lg:p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <span onClick={() => navigateTo("/")} className="-m-1.5 cursor-pointer p-1.5">
            <span className="sr-only">House of Hope</span>
            <EnLogo />
          </span>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <span
              onClick={() => navigateTo(item.href)}
              key={item.name}
              className={classNames(
                "cursor-pointer text-sm uppercase leading-6 tracking-wide text-gray-900 hover:text-prmOrangeHover",
                pathname === item.href ? " text-prmOrange" : "",
                isArm ? "font-helv" : ""
              )}
            >
              {item.name}
            </span>
          ))}
        </div>
        {!mobileMenuOpen && (
          <div className="flex flex-1 items-center justify-end text-prmDark lg:gap-4">
            <div className="flex flex-1 justify-center lg:justify-end">
              <DonationButton />
            </div>
            <div className="flex-0 flex">
              <LanguageDropDown />
            </div>
          </div>
        )}
        <div className="flex text-prmDark lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white p-3 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <span onClick={() => navigateTo("/")} className="-m-1.5 cursor-pointer p-1.5">
              <span className="sr-only">House of Hope</span>
              <EnLogo />
            </span>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="z-30 mt-6 flow-root">
            <div className="-my-6 divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <span
                    onClick={() => navigateTo(item.href)}
                    key={item.name}
                    className={classNames(
                      "-mx-3 block cursor-pointer rounded-lg px-3 py-2 text-base font-light uppercase leading-7 tracking-wider text-gray-900",
                      pathname === item.href ? " text-prmOrange" : "",
                      isArm ? "font-helv" : ""
                    )}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
