import { donateModalAtom, donorDetailsAtom } from "components/Donate/state";
import useIsTouchDevice from "hooks/useIsTouchDevice";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const DonateCard = ({ donation }) => {
  const { imageUrl, color } = donation;

  const language = useAtomValue(localeAtom);
  const isArm = language === "am";
  const { projectTitle, projectDescription, donationText, callToAction } = donation[language];

  // Global State
  const setIsModalOpen = useSetAtom(donateModalAtom);
  const [details, setCurrentCause] = useAtom(donorDetailsAtom);

  const isTouchDevice = useIsTouchDevice();

  const onClickHandler = () => {
    setCurrentCause({ ...details, cause: projectTitle });
    setIsModalOpen(true);
  };

  return (
    <div
      className={classNames(
        "min-w-72 relative mb-[1rem] aspect-[5/4] rounded-xl border lg:mb-0 lg:max-w-[32.5%]",
        "before:absolute before:inset-0 before:z-10 before:rounded-xl before:bg-[#1d1e20] before:bg-opacity-80"
      )}
    >
      <img
        className="absolute inset-0 h-full w-full rounded-xl object-cover"
        src={imageUrl}
        alt="test-image"
      />
      <div className="relative z-40 flex flex-col gap-2 p-[1.5rem] text-white xl:p-8">
        <h4
          className={classNames(
            "line-clamp-3 text-start uppercase tracking-wide sm:leading-tight ",
            "text-base 2xs:text-xl xs:text-2xl sm:text-base md:text-base xl:text-lg",
            isArm ? "font-kirk" : "font-semibold"
          )}
        >
          {projectTitle}
        </h4>
        <p className="line-clamp-3 text-start text-sm font-light 2xs:text-base sm:text-sm xl:text-base">
          {projectDescription}
        </p>
      </div>
      <div
        style={{ backgroundColor: color }}
        className={classNames(
          "absolute  bottom-5 left-0 z-20 cursor-pointer rounded-r-full bg-prmBlue py-1 pl-4 pr-1 md:bottom-8",
          isTouchDevice ? "max-w-[280px]" : "max-w-[280px] lg:max-w-[350px]"
        )}
      >
        <div
          onClick={onClickHandler}
          className="group flex items-center justify-center gap-2 overflow-hidden rounded-full bg-white p-1 px-3 lg:h-10"
        >
          <div
            className={classNames(
              "whitespace-nowrap uppercase transition-all duration-200",
              isArm ? "font-helv" : ""
            )}
          >
            {donationText}
          </div>
          <button
            className={classNames(
              "truncate-text text-start text-[0.6rem] transition-all duration-500",
              isTouchDevice ? "w-[280px]" : "w-[280px] lg:w-0 lg:group-hover:w-[350px]"
            )}
          >
            {callToAction}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DonateCard;
