import ContainerWrapper from "../../../ui/wrappers/container-wrapper.jsx";
import { classNames, transformedImageUrl } from "../../../utils";
import { aboutUsContent } from "../data/index.js";
import { history } from "../data/story.js";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { localeAtom } from "utils/locale/state.js";

function TheStory() {
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";
  const [isOpen, setIsOpen] = useState(false);

  const { mainTitleText, readMoreButtonText, readLessButtonText } = aboutUsContent[language];

  return (
    <div className="overflow-hidden">
      <ContainerWrapper>
        <div className="mb:mt-5 mt-2 flex justify-center md:justify-start">
          <h1
            className={classNames(
              "mb-10 text-[28px] font-bold uppercase text-prmOrange md:text-5xl",
              isArm ? "font-kirk" : ""
            )}
          >
            {mainTitleText}
          </h1>
        </div>
        {history
          .sort((a, b) => a.year - b.year)
          .slice(0, isOpen ? history.length : 1)
          .map((story, i) => {
            const isEven = (i & 1) === 0;
            return (
              <div
                key={i}
                className="flex-column  flex w-full items-center justify-center text-prmDark"
              >
                <div
                  className={classNames(
                    "relative flex flex-col-reverse  items-center justify-center md:gap-2",
                    isEven ? "md:flex-row-reverse" : "md:flex-row"
                  )}
                >
                  <span
                    className={classNames(
                      "-z-2 absolute font-kirk text-[150px] font-bold opacity-5 md:text-[300px]",
                      isEven ? "md:right-0" : "md:left-0"
                    )}
                  >
                    {story.year}
                  </span>
                  <div className="z-10 flex -translate-y-10 flex-col md:translate-y-0 md:pl-6 lg:pl-24">
                    <div className="lg:max-w-[80%]">
                      <h3
                        className={classNames(
                          "font-kirk text-6xl font-bold text-prmOrange md:text-7xl",
                          isEven ? "" : "text-end"
                        )}
                      >
                        {story.year}
                      </h3>
                      <p className="font-nuni text-sm font-light md:text-base lg:text-lg">
                        {story.text[language]}
                      </p>
                    </div>
                  </div>
                  <img
                    className={classNames(
                      "aspect-square w-full max-w-[50%] rounded-full object-cover  md:max-w-[40%] md:p-5",
                      isEven ? "self-end" : "self-start"
                    )}
                    src={transformedImageUrl({
                      url: story.img.url,
                      width: 1000
                    })}
                    alt="about-us-cover"
                  />
                </div>
              </div>
            );
          })}
        <div className="mt-10 flex w-full items-end justify-end">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="self-end rounded-full bg-prmOrange px-2 py-1 text-sm text-white hover:bg-prmOrangeHover"
          >
            {!isOpen ? readMoreButtonText : readLessButtonText}
          </button>
        </div>
      </ContainerWrapper>
    </div>
  );
}

export default TheStory;
