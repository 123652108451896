import LazyLoadYouTube from "./LazyLoadYouTube";
import { videoWrapperText } from "./content";
import { useAtomValue } from "jotai";
import ContainerTitle from "ui/wrappers/container-title";
import ContainerWrapper from "ui/wrappers/container-wrapper";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

function VideoWrapper() {
  const locale = useAtomValue(localeAtom);
  const isArm = locale === "am";
  const translation = videoWrapperText[locale];
  return (
    <ContainerWrapper>
      <ContainerTitle title={"aboutUs"} navTo={"/about-us"} />
      <div className="mt-3 flex w-full flex-col items-center justify-around gap-2 md:flex-row">
        <div className="w-full flex-1 p-1">
          <LazyLoadYouTube videoId={"Sy_BsVCt2QA"} />
        </div>
        <div className="flex h-full flex-1 flex-col justify-center gap-2 p-1 text-start text-prmDark sm:p-4">
          <span className="w-fit rounded-full bg-gray-200 px-2 py-1 text-xs tracking-widest">
            11.10.2023
          </span>
          <h2
            className={classNames(
              isArm ? "font-art text-xl md:text-3xl" : "font-geist font-bold sm:text-2xl"
            )}
          >
            {translation.header}
          </h2>
          <p className="text-sm font-light sm:text-base">{translation.paragraph}</p>
        </div>
      </div>
    </ContainerWrapper>
  );
}

export default VideoWrapper;
