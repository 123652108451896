import axios from "axios";
import ls from "localstorage-slim";

const BASE_URL = process.env.REACT_APP_SERVER;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = ls.get("house_admin");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
