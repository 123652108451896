export const donationTranslatedText = {
  en: {
    form: {
      title: "Select A Donation Amount",
      donateButton: "Donate",
      amountLabel: "Amount to Donate",
      nameLabel: "Name",
      emailLabel: "Email",
      anonymousLabel: "Anonymous donation",
      causeLabel: "Select the cause",
      validationAmountLabel: "Amount should not be empty or zero",
      validationNameLabel: "Name is a required field"
    }
  },
  de: {
    form: {
      title: "Auswählen oder Betrag eingeben",
      donateButton: "Jetzt Spenden",
      amountLabel: "Spendenbetrag",
      nameLabel: "Name",
      emailLabel: "Email",
      anonymousLabel: "Anonyme Spende",
      causeLabel: "Wählen Sie den Zweck aus",
      validationAmountLabel: "Der Betrag darf nicht leer oder null sein",
      validationNameLabel: "Name ist ein Pflichtfeld"
    }
  },
  am: {
    form: {
      title: "Ընտրել կամ Լրացնել",
      donateButton: "Նվիրաբերել",
      amountLabel: "նվիրաբերելու գումարը",
      nameLabel: "Անուն",
      emailLabel: "Էլ-հասցե",
      anonymousLabel: "Անանուն նվիրատվություն",
      causeLabel: "Ընտրել Նախագիծը",
      validationNameLabel: "Անունը պարտադիր դաշտ է",
      validationAmountLabel: "Դաշտը չպետք է դատարկ կամ զրո լինի"
    }
  }
};
