const ContainerWrapper = ({ children }) => {
  return (
    <section>
      <div className="relative mx-auto w-full max-w-7xl overflow-hidden px-4 py-8 sm:pb-12 lg:px-8">
        {children}
      </div>
    </section>
  );
};

export default ContainerWrapper;
