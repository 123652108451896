import { homeContent } from "../content";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function VolunteersModal(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailValue, setEmailValue] = useState({
    name: "",
    email: "",
    message: ""
  });
  const { language } = props;
  const { volunteers } = homeContent[language];

  const onSubmit = (e) => {
    e.preventDefault();
    //mailto
    window.location.href = `mailto:info@hoh.am?subject=${volunteers.mainPage.mainTitle}&body=${
      emailValue.message +
      "%0D%0A" +
      "%0D%0A" +
      emailValue.name +
      "%0D%0A" +
      emailValue.email +
      "%0D%0A" +
      phoneNumber
    }`;
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {volunteers.modalPage.modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* sends email on submit */}

        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={volunteers.modalPage.volunteerName}
              value={emailValue.name}
              onChange={(e) => setEmailValue({ ...emailValue, name: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder={volunteers.modalPage.volunteerEmail}
              value={emailValue.email}
              onChange={(e) => setEmailValue({ ...emailValue, email: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <PhoneInput
              placeholder={volunteers.modalPage.volunteerPhone}
              defaultCountry="AM"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={volunteers.modalPage.volunteerMessage}
              value={emailValue.message}
              onChange={(e) => setEmailValue({ ...emailValue, message: e.target.value })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" value="Send" onClick={onSubmit}>
          {volunteers.modalPage.volunteerConfirmButton}
        </Button>
        <Button onClick={props.onHide}>{volunteers.modalPage.volunteerCloseButton}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VolunteersModal;
