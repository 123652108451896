export const headerContent = {
  en: {
    news: "News",
    seeAll: "See All",
    projects: "Projects",
    contactUs: "Contact Us",
    volunteers: "Volunteers",
    aboutUs: "About Us"
  },
  de: {
    news: "Aktuelles",
    seeAll: "Alle Anzeigen",
    projects: "Projecte",
    contactUs: "Kontakt",
    volunteers: "Freiwillige",
    aboutUs: "Über uns"
  },
  am: {
    news: "Նորություններ",
    seeAll: "Տեսնել Բոլորը",
    projects: "Նախագծեր",
    contactUs: "Կապվել Մեզ Հետ",
    volunteers: "Կամավորներ",
    aboutUs: "Մեր Մասին"
  }
};
