export const testimonialData = [
  {
    avatar: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/q_auto/v1698090176/HouseOfHope/statics/photo-0_c3ctms.png"
    },
    header: {
      am: "Թանկագին բարեկամներ,",
      en: "Dear Friends",
      de: "Lieber Freunde"
    },
    content: {
      am: {
        name: "Մովսես Պողոսյան",
        testimonial:
          "Ուրախ եմ ողջունել Ձեզ «Հուսո տուն բարեգործական կենտրոն» հիմնադրամի կայքում: Մեր հիմնադրամը ժառանգել է, բարեհաջող շարունակում, ընդլայնում ու զարգացնում է մի խումբ նվիրյալների կողմից դեռևս 1994 թվականից Հայաստանում նախաձեռնված բարեգործական ծրագրերի իրականացումը, ինչը նպաստում է անապահով ընտանիքների, միայնակ, հենաշարժողական խնդիրներ ունեցող տարեցների կյանքի որակի բարելավմանը, ինչպես նաև երեխաների ու երիտասարդների կրթական մակարդակի բարձրացմանը։",
        title: "Հիմնադրամի տնօրեն"
      },
      en: {
        name: "Movses Poghosyan",
        testimonial:
          "Welcome to the 'House of Hope Charity Center' Foundation's official website. Your feedback on our activities and website is invaluable. Our foundation, building on charity projects initiated in Armenia since 1994, continues to improve life quality for vulnerable families, the elderly, and individuals with mobility disorders, while enhancing educational opportunities for children and youth. Our projects aim to alleviate social tensions, better beneficiaries' situations, and foster a charitable culture. We're grateful for the contributions from compatriots and benevolent foreign citizens, united in selfless aid. We warmly invite you to join our robust network of specialists and volunteers supporting us.",
        title: "Director of Foundation"
      },
      de: {
        name: "Movses Poghosyan",
        testimonial:
          'Herzlich willkommen auf der offiziellen Webseite der Stiftung "Wohltätigkeitszentrum Haus der Hoffnung". Ihre Meinungen und Vorschläge zu unserer Webseite und Aktivitäten sind uns sehr wichtig. Seit 1994 setzt unsere Stiftung Wohltätigkeitsprojekte fort, die das Leben bedürftiger Familien, älterer und behinderter Menschen verbessern und die Bildung von Kindern und Jugendlichen fördern. Unser Ziel ist, soziale Spannungen zu mindern, die Lage der Bedürftigen zu verbessern und die Wohltätigkeitskultur im Land zu fördern. Dank der Unterstützung unserer Landleute und herzlichen ausländischen Bürgern, vereint durch das selbstlose Bestreben zu helfen, wächst unsere Gemeinschaft. Wir laden Sie herzlich ein, sich unseren engagierten Förderern und Freiwilligen anzuschließen.',
        title: "Leiter der Stiftung"
      }
    }
  },
  {
    avatar: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/q_auto/v1698090172/HouseOfHope/statics/photo-1_i8h8lk.png",
      zoom: 3,
      width: 300,
      height: 300,
      focalPoints: [50, 50]
    },
    header: {
      am: "Թանկագին բարեկամներ,",
      en: "Dear Friends",
      de: "Lieber Freunde"
    },
    content: {
      am: {
        name: "Լուսինե Ազարյան",
        testimonial:
          "Հիմնադրամը ստեղծման առաջին իսկ օրվանից իր ակտիվ և արդյունավետ գործունեության շնորհիվ եղել և շարունակում է մնալ մի կառույց, որտեղ մարդիկ վերագտնում են բարեկեցիկ կյանքով ապրելու հույսը: Հիմնադրամի աշխատանքային կորիզը հանդիսանում են այն նվիրյալները, ովքեր շուրջ 30 տարի ներգրավված են եղել սոցիալական լարվածության նվազեցման դժվարին, բայց պատվաբեր գործին և որոնց շուրջը ձևավորվել է աշխատանքային մրցունակ թիմ, որին շրջապատում է կամավորների, աջակիցների և հովանավորների ստվար բանակը և որն առանձնանում է աշխատելու և արարելու հզոր կամքով, բարդ իրադրություններում անցնցում գործելու ունակությամբ, դժվարին խնդիրներ լուծելու կարողությամբ, ստեղծագործ մոտեցմամբ:",
        title: "«Գթության խոհանոց կառավարման կոմիտե-Օստալբի» խոսնակ"
      },
      en: {
        name: "Lusine Azaryan",
        testimonial:
          "Welcome to the 'House of Hope Charity Center' foundation website. Since inception, our active endeavors have made this a place where people rediscover hope for a prosperous life. The heart of our foundation is the devoted team combating social tension for nearly 30 years, now a competitive unit backed by a robust network of volunteers, supporters, and sponsors. Their strong resolve, problem-solving prowess, and creative approach are distinctive. Your attentive feedback, critical insights, and constructive suggestions are crucial to our success, for which I am grateful. I eagerly anticipate your involvement as volunteers, sponsors, and supporters, enhancing our collective impact.",
        title: "Chairman of the board of trustees"
      },
      de: {
        name: "Lusine Azaryan",
        testimonial:
          'Willkommen auf der Website der Stiftung "Wohltätigkeitszentrum Haus der Hoffnung". Seit Gründung steht die Stiftung für neuerliche Hoffnung auf ein erfülltes Leben. Seit fast 30 Jahren engagieren sich hingebungsvolle Anhänger in der ehrenvollen Aufgabe, soziale Spannungen zu mindern. Ein engagiertes Team, umgeben von Freiwilligen, Unterstützern und Sponsoren, zeichnet sich durch Tat- und Gestaltungskraft, Problemlösungsfähigkeit und kreative Ansätze aus. Der Erfolg der Stiftung spiegelt sich im wertvollen Feedback unserer Unterstützer wider, welches durch Aufmerksamkeit und konstruktive Vorschläge geäußert wird. Ihre Rückmeldungen sind unschätzbar, und wir freuen uns, Sie unter unseren Unterstützern zu wissen. Ihre Unterstützung und das Engagement jedes Einzelnen tragen maßgeblich zu unserem Erfolg bei.',
        title: "Vorsitzende des Stiftungsrates"
      }
    }
  },
  {
    avatar: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/e_brightness:35/e_contrast:-35/v1698090169/HouseOfHope/statics/photo-2_jguw6m.png",
      zoom: 2,
      width: 850,
      height: 850,
      focalPoints: [100, -50]
    },
    header: {
      am: "Թանկագին բարեկամներ,",
      en: "Dear Friends",
      de: "Lieber Freunde"
    },
    content: {
      am: {
        name: "Պավել Կլաուս",
        testimonial:
          "Սրտանց և անձնապես ողջունում եմ Ձեզ Գերմանիայում բնակվող բոլոր այն Հայաստանի բարեկամների կողմից, ովքեր շուրջ երեսուն տարի աջակցում են մեզ ձեր հիասքանչ երկրում իրականացնելու բազմաթիվ բարեգործական ծրագրեր։ Այդ ծրագրերի իրականացման, շարունականության ապահովման ողջ ծանրությունն ու պատասխանատվությունը մեծ հաջողությամբ արդեն յոթ տարի իր ուսերին է վերցրել «Հուսո տուն բարեգործական կենտրոն» հիմնադրամը։",
        title: "Հոգաբարձուների խորհրդի նախագահ"
      },
      en: {
        name: "Pavel Klaus",
        testimonial:
          "Heartfelt greetings from Armenian friends in Germany, who've supported us for thirty years in charity projects in your homeland. The 'House of Hope Charity Center' Foundation has led these efforts for seven years, ensuring project continuity. Your vigilant attention, constructive feedback, and support, direct or indirect, have been crucial. We hope for your continued support in our primary mission: enhancing the lives of the underprivileged, and providing hope for a brighter, happier future. Your support is invaluable, and deeply appreciated.",
        title: "Speaker of the 'Charity Kitchen' Steering Committee – Ostalb"
      },
      de: {
        name: "Pavel Klaus",
        testimonial:
          "Herzliche Grüße von vielen in Deutschland lebenden armenischen Freunden, die uns seit dreißig Jahren bei wohltätigen Projekten unterstützen. Die Stiftung „Wohltätigkeitszentrum Haus der Hoffnung“ trägt seit sieben Jahren erfolgreich die Verantwortung für diese Projekte und garantiert Kontinuität. Ihre aufmerksame Betrachtung, kritische Einschätzung und Verbesserungsvorschläge sind essentiell, ebenso wie Ihre direkte und indirekte Unterstützung. Wir schätzen Ihre dauerhafte Hilfe sehr, um unser Hauptziel zu erreichen: die Lebensbedingungen der Benachteiligten zu verbessern und Hoffnung auf eine bessere, glücklichere Zukunft zu bieten. Ihre Unterstützung ist unschätzbar und zutiefst geschätzt.",
        title: "Sprecher des Lenkungskreises „Küche der Barmherzigkeit“"
      }
    }
  }
];
