import TestimonialCard from "./TestimonialCard";
import { testimonialData } from "./content";
import { SwiperSlide } from "swiper/react";
import SwiperCarousel from "ui/swipers/swiper-carousel";
import ContainerWrapper from "ui/wrappers/container-wrapper";

export default function Testimonials() {
  return (
    <ContainerWrapper>
      <div className="relative my-6 h-full overflow-hidden">
        <SwiperCarousel slidesPerView={1}>
          {testimonialData.map((member, i) => (
            <SwiperSlide key={`member-${i}`}>
              <TestimonialCard member={member} />
            </SwiperSlide>
          ))}
        </SwiperCarousel>
      </div>
    </ContainerWrapper>
  );
}
