import useAxios from "../../../../../axios/useAxios";
import { formatDate } from "../../../../../utils/dates";
import { modalLang } from "../../../../../utils/languageHandler";
import { NewsContext, ProjectContext } from "../../../../Context";
import RichEditor from "../../../Editor";
import Error from "../../../Error";
import SmallLoader from "../../../SmallLoader";
import { editModalLanguage } from "../../content";
import { useAtomValue } from "jotai";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { localeAtom } from "utils/locale/state";

function EditModal({ type, data: editArticle, ...props }) {
  //context
  const fetchNews = useContext(NewsContext);
  const fetchProjects = useContext(ProjectContext);
  const isProject = type === "projects";

  //Global State
  const lang = useAtomValue(localeAtom);

  const {
    editModalTitle,
    editDateText,
    namePlaceholder,
    imagePlaceholder,
    contentPlaceholder,
    closeButtonText,
    confirmButtonText
  } = editModalLanguage(lang);

  const endpoint = editArticle ? `/${type}/${editArticle._id}` : `/${type}`;
  const method = editArticle ? "PUT" : "POST";

  const initialState = {
    title_am: editArticle?.title_am ?? "",
    description_am: editArticle?.description_am ?? "",
    title_de: editArticle?.title_de ?? "",
    description_de: editArticle?.description_de ?? "",
    title_en: editArticle?.title_en ?? "",
    description_en: editArticle?.description_en ?? "",
    isArchived: editArticle?.isArchived ?? false,
    date: formatDate(editArticle?.date) ?? "",
    pictures: editArticle?.pictures ?? []
  };

  const { error, loading, axiosFetch } = useAxios();

  // Local State
  const [article, setArticle] = useState(initialState);
  const [files, setFiles] = useState([]);
  const [language, setLanguage] = useState("am");

  const validPictures = files.length > 0;

  const currentDescription = useMemo(() => {
    return article[`description_${language}`];
  }, [article, language]);

  const handleContentChange = (content) => {
    setArticle((prevArticle) => ({
      ...prevArticle,
      [`description_${language}`]: content
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = await axiosFetch({
      method,
      url: endpoint,
      requestConfig: {
        data: article
      }
    });

    if (data && validPictures) {
      const formData = new FormData();
      [...files].forEach((file) => formData.append("pictures", file));

      if (article.pictures.length) {
        await axiosFetch({
          method: "post",
          url: `/${type}/${data._id}/delete-pictures`
        });
      }

      await axiosFetch({
        method: "post",
        url: `/${type}/${data._id}/pictures`,
        requestConfig: {
          data: formData
        }
      });

      if (!loading && !error) {
        props.onHide();
        !data && setArticle(initialState);
        if (type === "articles") {
          await fetchNews();
        } else {
          await fetchProjects();
        }
        setFiles([]);
      }
    } else if (data && files.length === 0) {
      if (type === "articles") {
        await fetchNews();
      } else {
        await fetchProjects();
      }

      props.onHide();
      setFiles([]);
      !data && setArticle(initialState);
    }
  };

  const handleClose = () => {
    setArticle(initialState);
    setFiles([]);
    props.onHide();
  };

  const fileSelectedHandler = (e) => {
    setFiles(e.target.files);
  };

  useEffect(
    () => {
      setArticle(initialState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editArticle]
  );

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title> {editModalTitle} </Modal.Title>{" "}
      </Modal.Header>
      {error && <Error />}
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {language === "am" ? (
            <>
              <Form.Group>
                <Form.Label>{namePlaceholder}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength={100}
                  placeholder="Enter the Title"
                  value={article.title_am}
                  onChange={(e) => setArticle({ ...article, title_am: e.target.value })}
                />
              </Form.Group>
            </>
          ) : language === "de" ? (
            <>
              <Form.Group>
                <Form.Label>{namePlaceholder}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength={100}
                  placeholder="Enter the Title"
                  value={article.title_de}
                  onChange={(e) => setArticle({ ...article, title_de: e.target.value })}
                />
              </Form.Group>
            </>
          ) : language === "en" ? (
            <>
              <Form.Group>
                <Form.Label>{namePlaceholder}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength={100}
                  placeholder="Enter the Titless"
                  value={article.title_en}
                  onChange={(e) => setArticle({ ...article, title_en: e.target.value })}
                />
              </Form.Group>
            </>
          ) : null}
          <Form.Group>
            <Form.Label>{contentPlaceholder}</Form.Label>
            <RichEditor
              playerName={`player-${language}`}
              content={currentDescription}
              onChange={handleContentChange}
            />
          </Form.Group>
          {!isProject && (
            <Form.Group>
              <Form.Label>{editDateText}</Form.Label>
              <Form.Control
                required
                type="date"
                rows={3}
                value={article.date}
                onChange={(e) => setArticle({ ...article, date: e.target.value })}
              />
            </Form.Group>
          )}
          {isProject && (
            <Form.Group className="my-3">
              <Form.Check
                checked={article.isArchived}
                onChange={(e) => setArticle({ ...article, isArchived: e.target.checked })}
                type="checkbox"
                label="Archive"
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>{imagePlaceholder}</Form.Label>
            <Form.Control required type="file" multiple size="sm" onChange={fileSelectedHandler} />
          </Form.Group>
        </Form>
        <div className="selected-language">
          <span
            onClick={() => setLanguage("am")}
            className={modalLang(language, "am") ? "selected" : ""}
          >
            ՀԱՅ
          </span>
          |
          <span
            onClick={() => setLanguage("de")}
            className={modalLang(language, "de") ? "selected" : ""}
          >
            DE
          </span>{" "}
          |
          <span
            onClick={() => setLanguage("en")}
            className={modalLang(language, "en") ? "selected" : ""}
          >
            EN
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose}>{closeButtonText}</button>
        {loading ? (
          <SmallLoader color="white" />
        ) : (
          <Button
            type="submit"
            disabled={editArticle ? false : !validPictures}
            onClick={handleSubmit}
          >
            {confirmButtonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EditModal;
