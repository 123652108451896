import useApi from "../../axios/useAxios";
import { classNames } from "../../utils";
import FluidContainer from "../UI/Layout/FluidContainer";
import { paymentStatusPageText } from "./content";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { HiCheckCircle, HiMinusCircle } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { localeAtom } from "utils/locale/state";

const StatusPage = () => {
  const { axiosFetch } = useApi();
  const [status, setStatus] = useState(null);
  const [queryParameters] = useSearchParams();
  const orderId = queryParameters.get("orderId");
  const navigate = useNavigate();

  const language = useAtomValue(localeAtom);
  const { greetingMessage, statusMessage, homeButtonText } = paymentStatusPageText[language];

  useEffect(
    () => {
      const paymentStatus = async () => {
        if (!orderId) {
          navigate("/");
        }

        const { data } = await axiosFetch({
          method: "post",
          url: "/payment/status",
          requestConfig: {
            data: { orderId }
          }
        });

        if (data) {
          setStatus(data);
        }

        return data;
      };
      paymentStatus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!status)
    return (
      <div className="flex-column flex h-screen w-full items-center justify-center gap-3">
        <div className="text-prmDark">...loading</div>
      </div>
    );

  const paymentStateMessage = status?.paymentAmountInfo?.paymentState;
  const isSuccessful = status?.orderStatus === 2;
  const donor = status?.cardAuthInfo?.cardholderName;

  return (
    <FluidContainer>
      <div className="flex-column flex h-screen w-full items-center justify-center gap-3 text-prmDark">
        <div className="flex-column flex items-center justify-center gap-2">
          {isSuccessful ? (
            <HiCheckCircle className="h-10 w-10 text-darkGreen " />
          ) : (
            <HiMinusCircle className=" h-10 w-10 text-red-800 " />
          )}
          <h1
            className={classNames(
              isSuccessful ? "text-darkGreen" : "text-red-800",
              "font-semibold"
            )}
          >
            PAYMENT {paymentStateMessage}!
          </h1>
          {isSuccessful && (
            <p className="text-center text-sm">{`${greetingMessage} ${donor} ${statusMessage}`}</p>
          )}
        </div>
        <a
          href="/"
          className="rounded-md bg-lightGreen px-4 py-2 text-base font-medium text-prmDark shadow-sm hover:bg-hoverGreen hover:text-prm"
        >
          {homeButtonText}
        </a>
      </div>
    </FluidContainer>
  );
};

export default StatusPage;
