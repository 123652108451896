import { donateModalAtom } from "../../Donate/state";
import { prioDonationText } from "./content";
import { useAtomValue, useSetAtom } from "jotai";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

function DonationButton() {
  const setMobileOpen = useSetAtom(donateModalAtom);

  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  const { donationText } = prioDonationText.childrenFood[language];

  const handleModal = () => {
    setMobileOpen(true);
  };

  return (
    <div>
      <button
        onClick={handleModal}
        className={classNames(
          "rounded-full bg-prmOrange px-3 py-1 text-sm font-medium uppercase text-white hover:bg-prmOrangeHover",
          isArm ? "font-helv" : ""
        )}
      >
        {donationText}
      </button>
    </div>
  );
}

export default DonationButton;
