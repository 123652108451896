import DynamicHelmet from "components/Helmet/DynamicHelmet";
import Infographic from "components/Home/Infographic/Infographic";
import NewsHome from "components/Home/NewsHome";
import PrioDonations from "components/Home/PrioDonations";
import ProjectHome from "components/Home/ProjectHome";
import Testimonials from "components/Home/Testimonials";
import VideoWrapper from "components/Home/VideoWrapper";
import Volunteer from "components/Home/Volunteers";
import Hero from "components/Home/hero";
import PageWrapper from "ui/wrappers/page-wrapper";

function Home() {
  return (
    <div className="mx-auto w-full max-w-screen-3xl">
      <DynamicHelmet title={"House of Hope - Yerevan"} />
      <Hero />
      <PageWrapper>
        <Infographic />
        <PrioDonations />
        <VideoWrapper />
        <NewsHome />
        <Testimonials />
        <ProjectHome />
        <Volunteer />
      </PageWrapper>
    </div>
  );
}

export default Home;
