import useClickOutside from "hooks/useClickOutside";
import { useAtomValue } from "jotai";
import { useRef, useState } from "react";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const DropDown = ({ items, className = "", callback, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const locale = useAtomValue(localeAtom);
  const isArm = locale === "am";

  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  function onClickCallback(e) {
    if (callback) {
      callback(e);
    }
    setIsOpen(false);
  }

  return (
    <div ref={dropdownRef} className={classNames("h-12 w-full", ...className)}>
      <div
        onClick={() => setIsOpen(true)}
        className="line-clamp-2 h-full w-full cursor-pointer p-1 text-xs font-light leading-5 text-prmDark"
      >
        {title}
      </div>
      {isOpen && (
        <div
          style={{ transform: "translate(-50%, -50%)" }}
          className={classNames(
            "absolute bottom-0 left-[50%] z-30 mx-auto flex h-fit w-[90%] flex-col rounded-xl border bg-white p-2 text-prmDark shadow-xl",
            isArm ? "font-helv text-sm" : "font-num text-lg"
          )}
        >
          {items.map((link, index) => (
            <button
              onClick={onClickCallback}
              key={index}
              className="line-clamp-1 w-full border-separate p-2 text-start font-medium leading-8 hover:bg-lightGray"
            >
              {link}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
