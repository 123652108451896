import { classNames } from "../../../utils";
import { contactDetailsText } from "./content";
import { useAtomValue } from "jotai";
import { localeAtom } from "utils/locale/state";

const FormButton = ({ loading, error, isReady, reset }) => {
  const locale = useAtomValue(localeAtom);
  const { form: { errorMessage, successMessage, button } = {} } = contactDetailsText(locale);

  if (loading) {
    return <span className="text-xs">...loading</span>;
  }

  if (error || isReady) {
    return (
      <div
        onClick={() => reset()}
        className={classNames(
          "rounded-sm  px-2 py-1 text-center text-xs",
          error ? "bg-red-400 text-white" : "bg-green-400 text-prmDark"
        )}
      >
        {error ? errorMessage : successMessage}
      </div>
    );
  }

  return (
    <button
      className="mt-2 rounded-full bg-prmOrange px-5 py-2 font-helv text-base uppercase text-white hover:opacity-95"
      type="submit"
    >
      {button}
    </button>
  );
};

export default FormButton;
