import { privacyTranslationText } from "./content";
import "./style.css";
import { useAtomValue } from "jotai";
import { localeAtom } from "utils/locale/state";

const PrivacyPolicy = () => {
  const language = useAtomValue(localeAtom);
  const {
    body: {
      privacyText,
      mainSubtitle,
      termsAndConditionsOne,
      termsAndConditionsTwo,
      refundSubtitle,
      refundTerms,
      refundCondition,
      refundRules
    } = {}
  } = privacyTranslationText[language];

  return (
    <div className="privacy-wrapper">
      <div className="privacy">
        <h2>{privacyText}</h2>
        <h4>{mainSubtitle}</h4>
        <p>{termsAndConditionsOne}</p>
        <p>{termsAndConditionsTwo}</p>
        <h4>{refundSubtitle}</h4>
        <ul>
          {refundTerms.map((term, i) => (
            <li key={i}>{term}</li>
          ))}
        </ul>
        <p>{refundCondition}</p>
        <div>
          {refundRules.map((rule, i) => (
            <h5 key={i}>* {rule}</h5>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
