export const history = [
  {
    year: 2021,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1698090152/HouseOfHope/statics/photo-three_tgzvrn.jpg"
    },
    text: {
      am: "2021թ․ քահանաներ Կառլ-Հայնց Շայդեն և Ալֆոնս Վենգերը փոխանցում են «Գթութան խոհանոց» ծրագրի համար Գերմանիայում հանգանակությունների համակարգման և Ծրագրի գերմանական կողմից ղեկավարման էստաֆետը արդեն թոշակի անցած Պավել Կլաուսին, ով իր հերթին ստեղծում է 6 անձից բաղկացած կառավարման կոմիտեն (Կլաուս Պավել՝ Օստալբի շրջանի նախկին նահանգապետ և կառավարման կոմիտեի նախագահ, Քրիստիան Բարոն՝ Շվեբիշ Գմյունդի առաջին փոխքաղաքապետ, Ռոբերտ Կլոկեր՝ կաթոլիկ եկեղեցու դեկան, Ուրսուլա Ռիխտեր՝ ավետարանչական եկեղեցու դեկան, Միխայել Լենգե՝ Gmünder Tagespost օրաթերթի խմբագրության ղեկավար, Դոկտոր Շթեֆան Շեֆոլդ՝ Լանդթագի նախկին պատգամավոր և նախկին պետքարտուղար)։",
      en: "In 2021, priests Karl-Heinz Scheiden and Alfons Wener handed over the coordination of collections in Germany and the German leadership of the 'Mercy Kitchen' program to the retired Pavel Klaus, who in turn formed a management committee consisting of 6 members (Klaus Pavel - former governor of the Ostalb district and chairman of the management committee, Christian Baron - first deputy mayor of Schwäbisch Gmünd, Robert Klocker - dean of the Catholic Church, Ursula Richter - dean of the Evangelical Church, Michael Lene - head of the editorial office of the Gmünder Tagespost newspaper, and Dr. Stefan Schefold - former member of the state parliament and former state secretary).",
      de: "Im Jahr 2021 übergaben die Priester Karl-Heinz Scheiden und Alfons Wener die Koordination der Sammlungen in Deutschland und die deutsche Leitung des 'Barmherzigkeitsküchen'-Programms an den pensionierten Pavel Klaus, der seinerseits ein Verwaltungskomitee aus 6 Mitgliedern bildete (Klaus Pavel - ehemaliger Gouverneur des Ostalbkreises und Vorsitzender des Verwaltungskomitees, Christian Baron - erster stellvertretender Bürgermeister von Schwäbisch Gmünd, Robert Klocker - Dekan der katholischen Kirche, Ursula Richter - Dekanin der evangelischen Kirche, Michael Lene - Leiter der Redaktion der Zeitung Gmünder Tagespost, und Dr. Stefan Schefold - ehemaliges Landtagsmitglied und ehemaliger Staatssekretär)."
    }
  },
  {
    year: 2020,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729641278/HouseOfHope/statics/84000689_2513157818790857_5232219358595383296_n_ags4jm.jpg"
    },
    text: {
      am: "2020թ․-ին «Գթության խոհանոց» ծրագրի և նրա ենթածրագրերի օպերատիվ իրականացումը ԳԿԽ ԲՎԵԿ մասնաճյուղի կողմից փոխանցվում է ՀՏԲԿ հիմնադրամին։",
      en: "In 2020, the operational implementation of the 'Mercy Kitchen' program and its sub-programs by the GRC BWEC branch was transferred to the HTHC foundation.",
      de: "Im Jahr 2020 wurde die operative Durchführung des 'Barmherzigkeitsküchen'-Programms und seiner Teilprogramme durch die DRK BWEC-Zweigstelle an die HTHC-Stiftung übertragen."
    }
  },
  {
    year: 2017,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729640945/HouseOfHope/statics/464033125_8295574907215757_3299064194608173032_n_ljzpfv.jpg"
    },
    text: {
      am: "2017թ․ ծրագրերի կայունության, շարունակականության, ինչպես նաև տեղում մարդկային, նյութական և ֆինանսական նվիրատվությունների ապահովման համար ստեղծվում է «Հուսո տուն բարեգործական կենտրոն» (ՀՏԲԿ) հիմնադրամը, որն իր մեջ համախմբում է այն ակտիվ կամավորներին, ովքեր կանգնած են եղել փրկարարական ջոկատի, Առաջին օգնության համակարգի ստեղծման և գործարկման հիմքում։",
      en: "In 2017, to ensure the stability and continuity of programs, as well as to secure human, material, and financial donations locally, the 'House of Hope Charitable Center' (HTHC) foundation was established, bringing together active volunteers who had been involved in the creation and launch of the rescue squad and the First Aid system.",
      de: "Im Jahr 2017 wurde die 'House of Hope Charitable Center' (HTHC)-Stiftung gegründet, um die Stabilität und Kontinuität der Programme sowie die Sicherstellung von personellen, materiellen und finanziellen Spenden vor Ort zu gewährleisten, und vereinte aktive Freiwillige, die an der Gründung und dem Start des Rettungstrupps und des Ersten-Hilfe-Systems beteiligt waren."
    }
  },
  {
    year: 2006,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729641987/HouseOfHope/statics/461299722_552076024158376_1114686974165377730_n_ifjfjy.jpg"
    },
    text: {
      am: "2006-ից սկսած իրականացվում է «Գթության խոհանոց» ծրագիրը և համակարգվում են բոլոր բարեգործական ծրագրերը։",
      en: "Since 2006, the 'Mercy Kitchen' program has been implemented, and all charitable programs have been coordinated.",
      de: "Seit 2006 wird das 'Barmherzigkeitsküchen'-Programm durchgeführt, und alle Wohltätigkeitsprogramme werden koordiniert."
    }
  },
  {
    year: 2005,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1732028087/HouseOfHope/statics/construction_ekdt38.webp"
    },
    text: {
      am: "2005թ․ Երևանի քաղաքապետարանի կողմից անհատույց օգտագործման նպատակով տրամադրված տարածքում Դրոյի 22/5 հասցեում սկսվում է «Հուսո տուն» բարեգործական կենտրոնի շենքի կառուցումը։",
      en: "In 2005, the construction of the 'House of Hope' Charitable Center building began at Dro 22/5, a location provided free of charge by the Yerevan Municipality.",
      de: "Im Jahr 2005 begann der Bau des 'House of Hope'-Wohltätigkeitszentrums in der Dro 22/5, einem von der Stadtverwaltung Eriwan kostenlos zur Verfügung gestellten Ort."
    }
  },
  {
    year: 2003,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729640070/HouseOfHope/statics/396953569_349492374416743_559671008794638731_n_rw9zgu.jpg"
    },
    text: {
      am: "2003թ․ կյանքի է կոչվում «Գթության խոհանոցի» «Տնային խնամք» ենթածրագիրը, որն իրականացվում է մինչ օրս։",
      en: "In 2003, the 'Home Care' sub-program of the 'Mercy Kitchen' was launched and has been running ever since.",
      de: "Im Jahr 2003 wurde das 'Hauspflege'-Teilprogramm der 'Barmherzigkeitsküche' ins Leben gerufen und wird bis heute durchgeführt."
    }
  },
  {
    year: 2002,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729693129/HouseOfHope/statics/2002_zyoega_zpmlfd.jpg"
    },
    text: {
      am: "2002թ․ Գերհարդ Մայերի ողբերգական մահից հետո բողոքական եկեղեցու քահանա Կառլ-Հայնց Շայդեն ստանձնում է Գերմանիայում «Գթության խոհանոց» ծրագրի համար հանգանակությունների համակարգումը։",
      en: "In 2002, after the tragic death of Gerhard Meyer, Protestant church pastor Karl-Heinz Scheiden took over the coordination of collections for the 'Mercy Kitchen' program in Germany.",
      de: "Im Jahr 2002 übernahm der protestantische Pfarrer Karl-Heinz Scheiden nach dem tragischen Tod von Gerhard Meyer die Koordination der Sammlungen für das 'Barmherzigkeitsküchen'-Programm in Deutschland."
    }
  },
  {
    year: 1995,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729639665/HouseOfHope/statics/274079825_4641027942670490_7538154454515695750_n_crliwj.jpg"
    },
    text: {
      am: "1995 |  «Գթության խոհանոց» ծրագրի ներքո նախաձեռնվում է «Մանկական սնունդ» ենթածրագիրը, որի նպատակն էր նվազեցնել տարբեր ռիսկի խմբերի մանուկների, պատանիների և աղջիկների սոցիալական խոցելիությունը։",
      en: "In 1995, the 'Child Nutrition' sub-program was initiated under the 'Mercy Kitchen' program with the aim of reducing the social vulnerability of children, teenagers, and girls from various at-risk groups.",
      de: "Im Jahr 1995 wurde das 'Kinderernährung'-Teilprogramm im Rahmen des 'Barmherzigkeitsküchen'-Programms ins Leben gerufen, um die soziale Verwundbarkeit von Kindern, Jugendlichen und Mädchen aus verschiedenen Risikogruppen zu verringern."
    }
  },
  {
    year: 1994,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729442282/HouseOfHope/statics/DALL_E_2024-10-20_18.37.20_-_A_soft_watercolor_painting_using_cool_blue_tones_and_gentle_washes_depicting_an_older_man_resembling_Gerhard_Meyer_seen_from_behind_inside_a_modest_m1weib.webp"
    },
    text: {
      am: "1994 | Գերհարդ Մայերը հանդես է գալիս ծայրահեղ կարիքի մեջ հայտնված տարեցներին ուղղված նախաձեռ­նու­թյամբ․ նա իր և ընկերների միջոցներով 1994թ․Երևանում նախաձեռնում է «Գթության խոհանոց» ծրագիրը՝ առաջին 150 շահառուի համար։",
      en: "In 1994, Gerhard Meyer initiated a program aimed at elderly people in extreme need, starting the 'Mercy Kitchen' in Yerevan for the first 150 beneficiaries with the help of his friends' resources.",
      de: "Im Jahr 1994 initiierte Gerhard Meyer ein Programm, das sich an ältere Menschen in extremer Not richtete, und startete das 'Barmherzigkeitsküchen'-Programm in Eriwan für die ersten 150 Begünstigten mit den Ressourcen seiner Freunde."
    }
  },
  {
    year: 1989,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/c_crop,x_-200,y_5,z_1,w_1000,ar_1/v1729429983/HouseOfHope/statics/1989_pzystu.png"
    },
    text: {
      am: "1989 | Գերմանական Կարմիր Խաչի լեռնափրկարարների խմբի մեջ է ընդգրկված լինում նաև Գերհարդ Մայերը, ով արդեն 1989թ․ փետրվարից սկսում է վերահսկել ոլորտում առաջին արհեստավարժ քայլերն իրականացնող կամավորական «Սպիտակ» փրկարար ջոկատի աշխատանքները...",
      en: "In 1989, Gerhard Meyer was also part of the German Red Cross mountain rescue team and, from February 1989, began overseeing the work of the volunteer 'Spitak' rescue squad, which was taking the first professional steps in the field...",
      de: "Im Jahr 1989 war Gerhard Meyer ebenfalls Mitglied der Bergrettungsgruppe des Deutschen Roten Kreuzes und begann ab Februar 1989, die Arbeit des Freiwilligen-Rettungstrupps 'Spitak' zu überwachen, der die ersten professionellen Schritte in diesem Bereich unternahm..."
    }
  },
  {
    year: 1988,
    img: {
      url: "https://res.cloudinary.com/di8iypeft/image/upload/v1729425075/HouseOfHope/statics/story1_qwrl0x.png"
    },
    text: {
      am: "1988 | Գերմանական Կարմիր Խաչի ներկայացուցիչները բարեգործական առաքելությամբ ժամանում են Հայաստան՝ աջակցություն ցուցաբերելու Սպիտակի ավերիչ երկրաշարժից տուժած մեր հայրենակիցներին։",
      en: "In 1988, representatives of the German Red Cross arrived in Armenia on a humanitarian mission to support our compatriots affected by the devastating Spitak earthquake.",
      de: "Im Jahr 1988 kommen Vertreter des Deutschen Roten Kreuzes im Rahmen einer humanitären Mission nach Armenien, um unsere Landsleute zu unterstützen, die von dem verheerenden Erdbeben in Spitak betroffen sind."
    }
  }
];
