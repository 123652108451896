import useAxios from "../../axios/useAxios";
import TeamMember from "./Team/TeamMember";
import { aboutUsContent } from "./data";
import { groupCategories } from "./utils";
import { adminContext } from "components/Context";
import Error from "components/Reusable/Error";
import Loader from "components/Reusable/Loader";
import { useAtomValue } from "jotai";
import { Fragment, useContext, useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperCarousel from "ui/swipers/swiper-carousel";
import ContainerWrapper from "ui/wrappers/container-wrapper";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const BoardOfTrusties = () => {
  const { isAdmin } = useContext(adminContext);
  const { response: team, error, loading, axiosFetch } = useAxios();
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  const {
    team: { trustees }
  } = aboutUsContent[language];

  async function getTeams() {
    axiosFetch({
      url: `teams`
    });
  }

  useEffect(
    () => {
      getTeams();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const groupedTeams = groupCategories(team);
  const trustiesData = groupedTeams["boardMember"];

  if (!trustiesData) {
    return null;
  }

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="our-team-wrapper w-full">
      <ContainerWrapper>
        <div className={classNames("our-team-header mb-5 uppercase", isArm ? "font-kirk" : "")}>
          <h2>{trustees}</h2>
        </div>
        <SwiperCarousel>
          {groupedTeams["boardMember"]
            ?.sort((a, b) => b.sequence - a.sequence)
            .map((member, i) => {
              return (
                <Fragment key={`${i}`}>
                  <div className="team-container">
                    <SwiperSlide key={member._id}>
                      <TeamMember member={member} reload={getTeams} admin={isAdmin} />
                    </SwiperSlide>
                  </div>
                </Fragment>
              );
            })}
        </SwiperCarousel>
      </ContainerWrapper>
    </div>
  );
};

export default BoardOfTrusties;
