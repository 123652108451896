import { prioDonationText } from "../../Home/PrioDonations/content";
import { FormCheckBox, FormInput, FormLabel, InputWrapper } from "../../UI/InputElements";
import { donationTranslatedText } from "../content";
import { donorDetailsAtom, errorAtom } from "../state";
import { useAtom, useAtomValue } from "jotai";
import DropDown from "ui/dropdown";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const DonorDetails = () => {
  const [details, setDetails] = useAtom(donorDetailsAtom);

  // Global State
  const locale = useAtomValue(localeAtom);
  const isArm = locale === "am";
  const errorMessage = useAtomValue(errorAtom);
  const { cause: currentCause } = useAtomValue(donorDetailsAtom);

  const { form: { nameLabel, emailLabel, causeLabel, validationNameLabel, anonymousLabel } = {} } =
    donationTranslatedText[locale];

  const formSelectProjects = Object.entries(prioDonationText).map(
    ([, value]) => value[locale].projectTitle
  );

  const isError = details.name === "" && errorMessage;

  function validatedLabel() {
    if (isError) {
      return validationNameLabel;
    }

    return `${nameLabel} *`;
  }

  return (
    <>
      <FormLabel isError={isError} label={validatedLabel()} forInput={"name"}>
        <InputWrapper>
          <FormInput
            id={"name"}
            type={"text"}
            callback={(e) => setDetails({ ...details, name: e.target.value })}
            value={details.name}
            required
          />
        </InputWrapper>
      </FormLabel>
      <FormLabel label={emailLabel} forInput={"email"}>
        <InputWrapper>
          <FormInput
            id={"email"}
            type={"email"}
            callback={(e) => setDetails({ ...details, email: e.target.value })}
            value={details.email}
          />
        </InputWrapper>
      </FormLabel>
      <FormLabel label={causeLabel} forInput={"cause"}>
        <InputWrapper>
          <DropDown
            items={formSelectProjects}
            title={currentCause || "Choose..."}
            callback={(e) => setDetails({ ...details, cause: e.target.innerText })}
          />
        </InputWrapper>
      </FormLabel>
      <div className="flex h-12 w-full items-center justify-center gap-3 text-white">
        <FormCheckBox
          id={"checkbox"}
          type={"checkbox"}
          callback={(e) => setDetails({ ...details, isAnonymous: e.target.checked })}
          value={details.isAnonymous}
        />
        <span
          className={classNames(
            "text-xs uppercase tracking-widest",
            isArm ? "font-helv" : "font-num"
          )}
        >
          {anonymousLabel}
        </span>
      </div>
    </>
  );
};

export default DonorDetails;
