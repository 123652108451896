import { classNames } from "../../../utils";

export default function FluidContainer({ dark = false, navId, children }) {
  return (
    <div
      id={navId}
      className={classNames(
        "mx-auto max-w-full px-4 py-6 sm:px-6 lg:px-8",
        dark ? "bg-prmDark" : ""
      )}
    >
      <div className="max-w-8xl mx-auto">{children}</div>
    </div>
  );
}
