import { shortParagraphText } from "./content";
import { useState } from "react";

function ShortParagraph({ data, language }) {
  const [isReadMoreSet, setIsReadMoreSet] = useState(false);
  const { readMoreButtonText, readLessButtonText } = shortParagraphText[language];

  return (
    <div className="mx-auto w-full">
      <div
        className={`${
          !isReadMoreSet && "line-clamp-3 md:line-clamp-2"
        } mt-3 text-sm font-light leading-6 text-prmDark sm:text-base`}
        dangerouslySetInnerHTML={{ __html: data }}
      ></div>
      {data?.length > 200 && (
        <button
          className="float-right mt-3 cursor-pointer border bg-yellow-400 px-2 py-1 text-[10px] font-light uppercase text-prmDark sm:text-[12px]"
          onClick={() => setIsReadMoreSet(!isReadMoreSet)}
        >
          {isReadMoreSet ? readLessButtonText : readMoreButtonText}
        </button>
      )}
    </div>
  );
}

export default ShortParagraph;
