import InfoGraphicItem from "./InfographicItem";
import { infoDataText } from "./infoData";
import { useAtomValue } from "jotai";
import { classNames } from "utils";
import { localeAtom } from "utils/locale/state";

const Infographic = () => {
  const language = useAtomValue(localeAtom);
  const translations = infoDataText[language]["stats"];

  return (
    <div className="relative flex h-full w-full justify-center">
      <div
        className={classNames(
          "item-center relative z-10 flex w-full flex-wrap justify-center rounded-lg bg-white px-6 py-2 shadow-lg sm:justify-between sm:py-6 md:px-12 2xl:px-20",
          "max-w-[75%] -translate-y-8 md:-translate-y-12 2xl:max-w-[65%] 2xl:-translate-y-16"
        )}
      >
        {translations.map((info, i) => (
          <InfoGraphicItem key={i} title={info.title} number={info.number} />
        ))}
      </div>
    </div>
  );
};

export default Infographic;
