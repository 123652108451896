import useAxios from "../../../axios/useAxios.jsx";
import { handleFormCloseLanguage, handleFormConfirmLanguage } from "../../Programs/content.js";
import Error from "../../Reusable/Error.jsx";
import SmallLoader from "../../Reusable/SmallLoader.js";
import { teamMemberContext } from "../Team";
import { aboutUsContent } from "../data/index.js";
import { useAtomValue } from "jotai";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { localeAtom } from "utils/locale/state.js";

function NewTeamMemberModal({ member, ...props }) {
  const { error, loading, axiosFetch } = useAxios();

  const getTeam = useContext(teamMemberContext);
  const method = member ? "PUT" : "POST";
  const endpoint = member
    ? `${process.env.REACT_APP_SERVER}/teams/${member._id}`
    : `${process.env.REACT_APP_SERVER}/teams`;

  const initialState = {
    sequence: member ? member.sequence : 0,
    name_am: member ? member.name_am : "",
    name_de: member ? member.name_de : "",
    name_en: member ? member.name_en : "",
    position_am: member ? member.position_am : "",
    position_de: member ? member.position_de : "",
    position_en: member ? member.position_en : "",
    category: member ? member.category : "projectTeam",
    avatar: member ? member.avatar : ""
  };

  const [team, setTeam] = useState(initialState);
  const [image, setImage] = useState(null);
  const language = useAtomValue(localeAtom);

  const { team: { newTeamMemberText } = {} } = aboutUsContent[language];
  // V A L I D A T I O N
  const validMemberSequence = team.sequence >= 0;
  const validMemberCategory = team.sequence >= 0;
  const validMemberNameAm = team.name_am.length > 0;
  const validMemberNameDe = team.name_de.length > 0;
  const validMemberNameEn = team.name_en.length > 0;
  const validPositionAm = team.position_am.length > 0;
  const validPositionDe = team.position_de.length > 0;
  const validPositionEn = team.position_en.length > 0;
  const validAvatar = image;

  const categoryOptions = ["projectTeam", "supportTeam", "management", "boardMember"];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: teamMember } = await axiosFetch({
        method,
        url: endpoint,
        requestConfig: {
          data: team
        }
      });
      if (teamMember && image !== null) {
        const formData = new FormData();
        formData.append("avatar", image);
        team.avatar.url &&
          (await axiosFetch({
            method: "POST",
            url: `/teams/${teamMember._id}/delete-avatar`
          }));
        const { data: avatar } = await axiosFetch({
          method: "POST",
          url: `/teams/${teamMember._id}/avatar`,
          requestConfig: {
            data: formData
          }
        });
        if (avatar) {
          setTeam(initialState);
          props.onHide();
          getTeam();
        }
      } else if (teamMember && image === null) {
        setTeam(initialState);
        props.onHide();
        getTeam();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setTeam(initialState);
    setImage(null);
    props.onHide();
  };

  useEffect(
    () => {
      setTeam(initialState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member]
  );

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>{newTeamMemberText}</Modal.Title>
      </Modal.Header>
      {error && <Error errorMessage={error} />}
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              isValid={validMemberSequence}
              isInvalid={!validMemberSequence}
              type="number"
              placeholder="Տեղակայման համարը"
              value={team.sequence}
              onChange={(e) => setTeam({ ...team, sequence: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={validMemberNameAm}
              isInvalid={!validMemberNameAm}
              type="text"
              placeholder="Աշխատակցի անոնը"
              value={team.name_am}
              onChange={(e) => setTeam({ ...team, name_am: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={validPositionAm}
              isInvalid={!validPositionAm}
              type="text"
              placeholder="Պաշտոնը"
              value={team.position_am}
              onChange={(e) => setTeam({ ...team, position_am: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={validMemberNameDe}
              isInvalid={!validMemberNameDe}
              type="text"
              placeholder="Name"
              value={team.name_de}
              onChange={(e) => setTeam({ ...team, name_de: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={validPositionDe}
              isInvalid={!validPositionDe}
              type="text"
              placeholder="Stellung"
              value={team.position_de}
              onChange={(e) => setTeam({ ...team, position_de: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={validMemberNameEn}
              isInvalid={!validMemberNameEn}
              type="text"
              placeholder="Employee's Name"
              value={team.name_en}
              onChange={(e) => setTeam({ ...team, name_en: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={validPositionEn}
              isInvalid={!validPositionEn}
              type="text"
              placeholder="Position"
              value={team.position_en}
              onChange={(e) => setTeam({ ...team, position_en: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Select
              isValid={validMemberCategory}
              isInvalid={!validMemberCategory}
              placeholder="Category"
              value={team.category}
              onChange={(e) => setTeam({ ...team, category: e.target.value })}
            >
              {categoryOptions.map((cat, i) => (
                <option key={`${cat}-${i}`} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Control
              isValid={!member ? validAvatar : false}
              isInvalid={!member ? !validAvatar : false}
              type="file"
              size="sm"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{handleFormCloseLanguage(language)}</Button>
        {loading ? (
          <SmallLoader color="white" />
        ) : (
          <Button
            type="submit"
            disabled={
              !validMemberNameAm ||
              !validMemberNameDe ||
              !validPositionAm ||
              !validPositionDe ||
              !validMemberCategory ||
              (!member ? !validAvatar : null)
            }
            onClick={handleSubmit}
          >
            {handleFormConfirmLanguage(language)}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default NewTeamMemberModal;
