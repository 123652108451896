import { AvatarBlob } from "components/icons/avatar-blob";
import { useAtomValue } from "jotai";
import { classNames, transformedImageUrl } from "utils";
import { localeAtom } from "utils/locale/state";

const TestimonialCard = ({ member }) => {
  const { avatar, header, content } = member;

  const locale = useAtomValue(localeAtom);
  const { name, testimonial, title } = content[locale];
  const headerContent = header[locale];
  const isArm = locale === "am";

  const imageUrl = transformedImageUrl({
    url: avatar.url,
    width: avatar.width,
    height: avatar.height,
    focusPoints: avatar.focalPoints,
    zoom: avatar.zoom
  });

  return (
    <div className="mx-auto mb-3 flex h-full max-w-7xl flex-col items-center justify-center gap-6 md:flex-row">
      <div className="relative flex w-[60%] items-center justify-center sm:w-[55%] md:w-[80%]">
        <div className="aspect-square h-full w-full  md:w-[80%] xl:w-[60%]">
          <div className="h-full">
            <AvatarBlob />
            <img
              className="relative h-full w-full rounded-full object-contain"
              src={imageUrl}
              alt={`house-of-hope-${title}`}
            />
          </div>
        </div>
      </div>
      <div className={classNames("w-full text-prmDark")}>
        <header
          className={classNames(
            "uppercase tracking-wide",
            isArm ? "font-art text-2xl" : "text-xl font-semibold "
          )}
        >
          {headerContent}
        </header>
        <figure className="relative isolate pt-2">
          <blockquote className={classNames("text-justify text-sm font-light sm:text-base")}>
            <p>{testimonial}</p>
          </blockquote>
          <figcaption className={classNames("mt-2 text-end text-base", isArm ? "" : "font-num")}>
            <div className="font-semibold">{name}</div>
            <div className="font-light">{title}</div>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default TestimonialCard;
