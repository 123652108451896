import AdminTools from "../../Reusable/AdminTools";
import MemberDeleteModal from "../Modals/MemberDeleteModal.jsx";
import NewTeamMemberModal from "../Modals/NewTeamMemberModal.jsx";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { localeAtom } from "utils/locale/state";

function TeamMember({ member, admin }) {
  const [modalShow, setModalShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const language = useAtomValue(localeAtom);
  const isArm = language === "am";

  return (
    <div className="team-member">
      <div className="team-member-wrapper">
        <div className="member-header">
          <img src={member?.avatar?.url} alt="team-member" />
        </div>
        <div className="member-body">
          <h4 className={isArm ? "font-kirk" : ""}>{member[`name_${language}`]}</h4>
          <p>{member[`position_${language}`]}</p>
        </div>
        <AdminTools
          showAdminModal={(v) => setModalShow(v)}
          showDeleteModal={(v) => setShowDeleteModal(v)}
          isAdmin={admin}
        />
      </div>
      <NewTeamMemberModal member={member} show={modalShow} onHide={() => setModalShow(false)} />
      <MemberDeleteModal
        member={member}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        language={language}
      />
    </div>
  );
}

export default TeamMember;
